import { ChangeDetectorRef,Renderer2,Component, Inject, OnInit, ViewChild } from '@angular/core';
import { Validators, FormGroup, FormControl } from '@angular/forms';
import { WebService}  from '../services/web/web.service';
import { ToastService } from '../services/notification/toast.service';
import {SpinnerService} from '../services/spinner/spinner.service';
import {ActivatedRoute, Router} from '@angular/router';
import {DatePipe, DOCUMENT} from '@angular/common';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { interval, Observable, of, timer,Subject } from 'rxjs';
import { DomSanitizer } from '@angular/platform-browser';
import { catchError, map, switchMap, delay,takeWhile  } from 'rxjs/operators';
import { UniqueCodeService } from './unique-code.service';
import { FacPopupComponent } from '../account-settings/plans/fac-popup/fac-popup.component';
import { environment } from '../../environments/environment';
import {AppConfirmService} from "../app-confirm/app-confirm.service";
import { MatTableDataSource } from '@angular/material/table';
import {MatPaginator, PageEvent} from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { ToastrService } from 'ngx-toastr';
import {TableService} from "../services/pager/pager";
import {ReportFilter} from "../customer-invoices/report.filter";
import {SecurityService} from "../services/security.service";
@Component({
  selector: 'app-add-client',
  templateUrl: './add-client.component.html',
  styleUrls: ['./add-client.component.scss'],
  providers : [DatePipe]
})
export class AddClientComponent implements OnInit {
  isPopupVisible: boolean = false; // Estado inicial del popup
  clientForm: FormGroup ;
  paymentForm: FormGroup ;
  plans : any;
  currencies : any;
  numbers : any;
  exist : Boolean = false;
  onboardingConfigID: any;
  provinces: any[]= [] ;
  onboardingConfigId : any;
  corregimientos: any[]= [] ;
  displayedColumns: string[] = ['select','ID', 'name_customer', 'email_customer', 'actions'];
  // PARA MANEJO DE PANEL DE BUSQUEDA
  isOpenSearchPanel = false;
  filter = new ReportFilter(this.tableService.filter);
  dataSource: any;
  items: any[] = [];

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;


     // NECESARIOS PARA 3DS DINAMICO
     retryCount = 0;
     maxRetries = 5;
     uniqueCode: string = "";
     myIp: string = '189.127.166.102';
     myHeight: number;
     myWidth: number;
     jwtTokenForThird: string;
     transactionIdForThird: string;
     transactionId: string;
     iframeValue: string;
     iframeName: string;
     iframeUrl: string;
     redirectUrl: string;
     dataObject: any;
     private pollingSuccess = new Subject<void>();
     iframeElement: HTMLIFrameElement;
  hasPlanID: any;
  emailCustomer: any;
   
  togglePopup(isVisible: boolean): void {
    if(!this.clientForm.valid){
      return this.toast.showInfo("Por favor complete el formulario de Datos del cliente");
    }
    this.isPopupVisible = isVisible;
  }
  constructor(private securityService : SecurityService,
      private toastr: ToastrService,
               private cdr: ChangeDetectorRef,
               public  tableService: TableService<any>,
    private uniqueCodeService: UniqueCodeService,
    @Inject(DOCUMENT) private document: Document,
    private renderer: Renderer2,
    private sanitizer: DomSanitizer,
                  private datePipe : DatePipe,
    private dialog: MatDialog,
    // public dialogRef: MatDialogRef<FacPopupComponent>
    private activateRoute : ActivatedRoute,private router : Router,private spinner : SpinnerService,private webService : WebService,private toast : ToastService) {
    this.clientForm = new FormGroup({
      name_customer: new FormControl('', Validators.required),
      lastName_customer: new FormControl('', Validators.required),
      identifier_customer : new FormControl('', Validators.required),
      email_customer : new FormControl('',[Validators.required]),
      phoneNumber_customer: new FormControl('', Validators.required),
      code_customer: new FormControl(''),
      config: new FormControl(''),
      status_customer: new FormControl(1),
      service: new FormControl(''),
      plan :  new FormControl('',Validators.required),
      partner :  new FormControl(''),
      onboardingSuscription :  new FormControl(true),
      address: new FormControl('', Validators.required),
      province : new FormControl(''),
      corregimiento : new FormControl('', Validators.required),
      start_date : new FormControl(new Date(), Validators.required)
    });
    this.paymentForm = new FormGroup({
      nameHolderCreditCard: new FormControl('', Validators.required),
      numberCreditCard: new FormControl('', Validators.required),
      expMonthCreditCard : new FormControl('', Validators.required),
      expYearCreditCard : new FormControl('',[Validators.required]),
      ccv: new FormControl('', Validators.required),
    });
   }

   list(event?: PageEvent){
    this.spinner.open();
     this.items = [];
     this.tableService.filter = new ReportFilter(this.filter);
     let httpParams = this.webService.buildRequestParams(this.tableService.sort,"m",
         {pageIndex: event ? event.pageIndex  : this.tableService.pager.pageIndex , pageSize: event ? event.pageSize : this.tableService.pager.pageSize})

     httpParams = this.filter.getHttpParams(httpParams);
     this.webService.get(this.webService.SECURITY_HOST + "/onboarding/" + this.onboardingConfigID + "/suscriptions/pending",httpParams)
         .subscribe(response => {
           this.items = response.result
           if (this.items.length === 0) {
             this.dataSource = [];
             this.dataSource = new MatTableDataSource<any>(this.items);
             this.spinner.close();
             return;
           }

           this.dataSource = new MatTableDataSource<any>(this.items);
           this.tableService.pager = response.pager;
           this.tableService.selection.clear();

           this.spinner.close();

         }, error => {
           console.log(error);
           this.spinner.close();
           this.toast.showError(error.message);
         });


   }

   ngOnInit(): void {
    // this.dataSource.paginator = this.paginator;
    // this.dataSource.sort = this.sort;
      this.spinner.open();
      this.initializeDimensions();
      this.subscribeToPollingSuccess();
      this.activateRoute.params.subscribe(params =>{
        this.spinner.close();
        this.clientForm.controls["config"].setValue(params["id"]);
        this.onboardingConfigID = params["id"];
        this.getConfigByID(params["id"]);
        this.list();
        this.spinner.open()
        this.webService.get(this.webService.SECURITY_HOST + "/province").subscribe( provinces =>{
          
          this.provinces = provinces.result.sort((a,b) =>  (a.name > b.name ? 1 : -1));
  
        }, err =>{
          this.spinner.close();
          this.toast.showError(err);
        });

      }, err=>{
        this.spinner.close();
        this.toast.showError(err);
      })
  }
  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  isAllSelected() {
    // return this.dataSource.every((client) => client.selected);
  }

  hasPartialSelection() {
    // return this.dataSource.some((client) => client.selected) && !this.isAllSelected();
  }

  hasSelectedUsers(): boolean {
    // return this.dataSource.some((client) => client.selected);
    return true;
  }

  toggleSelectAll(event: Event) {
    const isChecked = (event.target as HTMLInputElement).checked;
    this.dataSource.forEach((client) => (client.selected = isChecked));
  }

  resendEmail(user: any) {
    this.spinner.open();
    this.webService.post({}, this.webService.SECURITY_HOST + "/customerHasPlan/" + user.id).subscribe( response => {
      console.log(`Correo reenviado a ${user.customerEmail}`);
      this.toastr.success(`Correo reenviado a ${user.customerEmail}`);
      this.ngOnInit();
    }, error => {
      this.spinner.close();
      this.toast.showError(error.message);
    });

  }

  async resendEmailsToSelected() {
    const selectedClients = this.tableService.selection.selected;
    if (selectedClients.length === 0) {
      this.toastr.info('No hay usuarios seleccionados para reenviar correos.');
      return;
    }
    this.spinner.open();
    try {
      const cancelPromises = selectedClients.map(client =>
          this.webService.post({}, this.webService.SECURITY_HOST + "/customerHasPlan/" + client.id).toPromise()
      );

      await Promise.all(cancelPromises);
      this.spinner.close();
      this.toastr.success(`Correos reenviados a ${selectedClients.length} usuarios.`);
      this.ngOnInit();
    } catch (error) {
      this.spinner.close();
      // @ts-ignore
      this.toast.showError(error.message);
    }
  }

  async cancelSuscriptionsSelected() {
    const selectedClients = this.tableService.selection.selected;
    if (selectedClients.length === 0) {
      this.toastr.info('No hay usuarios seleccionados para reenviar correos.');
      return;
    }

    this.spinner.open();
    try {
      const cancelPromises = selectedClients.map(client =>
          this.webService.put({}, this.webService.SECURITY_HOST + "/customerHasPlan/" + client.id).toPromise()
      );

      await Promise.all(cancelPromises);
      this.spinner.close();
      this.toastr.success(`Cancelando suscripcion a ${selectedClients.length} usuarios.`);
      this.ngOnInit();
    } catch (error) {
      this.spinner.close();
      // @ts-ignore
      this.toast.showError(error.message);
    }
  }

  cancelSubscription(user: any) {
    this.spinner.open();
    this.webService.put({}, this.webService.SECURITY_HOST + "/customerHasPlan/" + user.id).subscribe( response => {
      console.log(`Cancelando suscripción a ${user.customerEmail}`);
      this.spinner.close();
      this.toastr.success(`Suscripción cancelada a ${user.customerEmail}`);
      this.ngOnInit();
    }, error => {
      this.spinner.close();
      this.toast.showError(error.message);
    });
  }
  // 3DS DINAMICO
  ngAfterViewInit(): void {
    setTimeout(() => {
      const uniqueCode = this.generateUniqueCode();
      this.uniqueCodeService.setUniqueCode(uniqueCode);
      this.uniqueCode = uniqueCode;
      this.addUniqueCodeToHead(uniqueCode);
      this.addUniqueCodeToBody(uniqueCode);
      this.cdr.detectChanges();
    }, 0);
  }

  private addUniqueCodeToHead(uniqueCode: string): void {
    const comment = this.renderer.createComment('script head nico');
    this.renderer.appendChild(this.document.head, comment);
    const script = this.renderer.createElement('script');
    this.renderer.setAttribute(script, 'type', 'text/javascript');
    this.renderer.setAttribute(script, 'src', `https://h.online-metrix.net/fp/tags.js?org_id=${environment.orgId}&session_id=bg_avocadoprime${uniqueCode}`);
    this.renderer.appendChild(this.document.head, script);

    script.onload = () => console.log('Script loaded successfully.');
    script.onerror = () => console.error('Failed to load the script.');
  }

  private addUniqueCodeToBody(uniqueCode: string): void {
    const noscript = this.renderer.createElement('noscript');
    const iframe = this.renderer.createElement('iframe');
    this.renderer.setStyle(iframe, 'width', '100px');
    this.renderer.setStyle(iframe, 'height', '100px');
    this.renderer.setStyle(iframe, 'border', '0');
    this.renderer.setStyle(iframe, 'position', 'absolute');
    this.renderer.setStyle(iframe, 'top', '-5000px');
    this.renderer.setAttribute(iframe, 'src', `https://h.online-metrix.net/fp/tags?org_id=${environment.orgId}&session_id=bg_avocadoprime${uniqueCode}`);
    this.renderer.appendChild(noscript, iframe);
    this.renderer.appendChild(this.document.body, noscript);
  }

  private generateUniqueCode(): string {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      const r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
  }

  private initializeDimensions(): void {
    this.myHeight = window.innerHeight;
    this.myWidth = window.innerWidth;
  }
  
  private subscribeToPollingSuccess(): void {
    this.pollingSuccess.subscribe(() => this.continueToNextStep());
  }

  onProvinceChange(){

    this.corregimientos = [];
    this.corregimientos = this.provinces[this.provinces.findIndex(t => t.id == Number(this.clientForm.value.province) )].corregimientos;
    this.corregimientos.sort((a,b) =>  (a.name > b.name ? 1 : -1));

  }

  getConfigByID(id : any){
    // BUSCAMOS TODOS LOS BANCOS
    let partner = -1;
    if(this.securityService.getTokenPartner() != null || this.securityService.getTokenPartner() != undefined){
      partner = this.securityService.getTokenPartner()
    }
    this.webService.get(this.webService.SECURITY_HOST + "/onboardingConfig/" +id+ "?partner=" + partner).subscribe( config =>{
      this.spinner.close();
      this.plans = config.result.plans.filter((p: { status_plan: number | any[]; }) => p.status_plan == 1).sort((a: { name_plan: string; }, b: { name_plan: string; }) => a.name_plan.localeCompare(b.name_plan));
      this.clientForm.controls["partner"].setValue({ id : +config.result.partner.id});
      this.clientForm.controls["service"].setValue(+config.result.service.id);
    }, err =>{
      this.spinner.close();
      this.toast.showError(err);
    });
  }

   send(emailSuscription : boolean){

    if(!this.clientForm.valid){
      return this.toast.showInfo("Por favor complete el formulario de Datos del cliente");
    }
    if(!emailSuscription){
      if(!this.paymentForm.valid){
        return this.toast.showInfo("Por favor complete el formulario de Datos del pago");
      }
    }
    

    this.spinner.open();
    this.clientForm.value.code_customer = this.clientForm.value.email_customer;
    // console.log(this.webService.SECURITY_HOST + "/onboarding/user");
    // console.log(JSON.stringify(this.clientForm.value));
    // console.log(JSON.stringify(this.clientForm.value, null, 2));

     this.webService.post(this.clientForm.value, this.webService.SECURITY_HOST + "/onboarding/user").subscribe(response =>{
       // console.log("RESPONSE DE CREAR USUARIO: ", response)
      let address : any = {};
      address.name = this.clientForm.value.name;
      address.lastname = this.clientForm.value.lastname;
      address.address = this.clientForm.value.address;
      address.province = this.clientForm.value.province;
      address.corregimiento = this.clientForm.value.corregimiento;

      let start_date = this.datePipe.transform(this.clientForm.value.start_date, 'yyyy-MM-dd'); // TOMAMOS LA FECHA DE INICIO DE LA SUSCRIPCION
      var subscription ;
      if(emailSuscription) {
        subscription = { start_suscription_date : start_date,   suscribeByEmail : emailSuscription, onboardingClientSuscription : !emailSuscription ,address:address,param: 8, customer:  +response.result.id, plan : +this.clientForm.value.plan}
      } else {
        subscription = { start_suscription_date : start_date,   suscribeByEmail : emailSuscription, onboardingClientSuscription : !emailSuscription ,address:address,param: 8, creditcard: this.paymentForm.value, customer:  +response.result.id, plan : +this.clientForm.value.plan}
      }
       // console.log(JSON.stringify(subscription, null, 2));
       // console.log(JSON.stringify(this.clientForm.value, null, 2));
      this.webService.post(subscription, this.webService.SECURITY_HOST + "/suscription").subscribe(response =>{
        this.spinner.close();
        this.toast.showSuccess("Suscripcion procesada correctamente");
        this.goBack();
        // this.handleResponse(response)
      }, err=>{
        this.spinner.close();
        this.toast.showError(err);
      });
    }, err =>{
      this.toast.showError(err.message);
      console.log(err);
      this.spinner.close();
    });
    

   }

  private createAddressFromForm() {
    return {
      name: this.clientForm.value.name,
      lastname: this.clientForm.value.lastname,
      address: this.clientForm.value.address,
      province: this.clientForm.value.province,
      corregimiento: this.clientForm.value.corregimiento
    };
  }

   goBack(){
    this.router.navigate(["/my-portals"]); 
  }

   omit_special_char(event: { charCode: any; }){   
    var k;  
    k = event.charCode;  //         k = event.keyCode;  (Both can be used)
    return((k > 64 && k < 91) || (k > 96 && k < 123) || k == 8 || k == 32 || (k >= 48 && k <= 57)); 
   }

   onlyNumberKey(event : any){
    return (event.charCode == 8 || event.charCode == 0) ? null : event.charCode >= 48 && event.charCode <= 57;
  }

  myLoadEvent() {
    this.spinner.open();
    this.webService.get(this.webService.SECURITY_HOST + "/hasplan/" + this.hasPlanID).subscribe(chp => {
      this.spinner.close();
      if (chp.result.status_customerPlan != 4) {
        if (chp.result.status_customerPlan == 1) { // ESTO ES QUE SE COBRO Y TODO BIEN CON LA SUSCRIPCION ESTA ACTIVA
          this.emailCustomer = chp.result.customer.email_customer;
          this.router.navigateByUrl("/congratulation");
        } else {
          this.toast.showError("Su pago fue rechazado por el banco");
        }
      }
    }, err => {
      this.spinner.close();
      this.toast.showError(err);
    });
  }

  // 3DS DINAMICO
  private handleResponse(response: any): void {
    console.log("RESPONSE DEL PROCESO 3DS: ", response)
    this.hasPlanID = response.id;

    if (response.bank === 'powerTranz') {
      console.log("POWERTRANZ")
      this.hasPlanID = response.id;
      this.handlePowerTranzResponse(response);
    } else if (response.bank === 'cyberSource') {
      console.log("CYBERSOURCE")
      this.handleCyberSourceResponse(response);
    } else if (response.bank === 'emetec') {
      console.log("EMETEC")
      this.handleEmetecResponse(response);
    } else {
      if(response.message == "Successfull created"){ // LA TARJETA PUEDE ESTAR VALIDA PARA 3DS
        this.hasPlanID = response.result;
        this.myLoadEvent();
      }else{
        this.toast.showError("ESTE BANCO NO ESTA REGISTRADO");
      }
    }
  }
  // TERMINA PROCESO DE 3DS DINAMICO
    
  // PROCESO PARA PROWERTRANZ
    private handlePowerTranzResponse(response: any): void {
      if (response.htmlFormData) {
        this.spinner.close();
        this.openFacPopup(response.htmlFormData);
      } else {
        this.hasPlanID = response.result;
        // this.emailCustomer = this.form.value.email;
        this.myLoadEvent();
      }
    }
  
    private openFacPopup(htmlFormData: string): void {
      const dialogRef = this.dialog.open(FacPopupComponent, {
        width: '50%',
        disableClose: true,
        data: { form: this.sanitizer.bypassSecurityTrustHtml(htmlFormData), id: this.hasPlanID }
      });
  
      dialogRef.afterClosed().subscribe(() => {
        this.myLoadEvent();
      });
    }
    // TERMINA PROCESO PARA POWERTRANZ

    // PROCESO PARA CYBERSOURCE
  private handleCyberSourceResponse(response: any): void {
    this.spinner.open();
    if (response.sopResponse.responseCodeDescription !== "COMPLETED") {
      this.spinner.close();
      this.toast.showError("ERROR - PAYER AUTH DISTINTO DE COMPLETE");
      return;
    }

    const transactionIdentifier = response.sopResponse.transactionId;
    const jwtToken = response.sopResponse.auth3DSDetail.token;
    this.uniqueCodeService.setTransactionIdentifier(transactionIdentifier);
    this.createAndSubmitIframeForm(jwtToken, "https://centinelapistag.cardinalcommerce.com/V1/Cruise/Collect", "ddc-iframe", "ddc-form");

    setTimeout(() => {
      const updatedData = this.transactionenrollment();
      const json = { chp: response.id, sop: updatedData, call: "enrollment" };
      console.log("CON ESTO HACEMOS EL ENROLLMENT: ",  json)
      this.webService.post(json, `${this.webService.SECURITY_HOST}/sop`).subscribe(enrollment => {
        this.handleEnrollmentResponse(enrollment);
      }, err => {
        this.spinner.close()
        console.error("ERROR LLAMANDO A ENRONLLMENT: ", err);
      });
    }, 10000);
  }

  private handleEnrollmentResponse(enrollment: any): void {
    console.log("RESPUESTA DEL ENROLLMENT: ", enrollment);
  
    switch (enrollment.responseCodeDescription) {
      case "AUTHENTICATION_SUCCESSFUL":
        console.log("Successful Frictionless Authentication");
        this.handleEnrollmentSuccess(enrollment);
        break;
  
      case "AUTHENTICATION_FAILED":
        console.log("Unsuccessful Frictionless Authentication");
        this.spinner.close();
        this.toast.showError("AUTHENTICATION_FAILED");
        break;
  
      case "PENDING_AUTHENTICATION":
        console.log("Pending Authentication - Step-Up Required");
        this.handlePendingAuthentication(enrollment);
        break;
  
      default:
        console.log("Unexpected response code from enrollment");
        this.spinner.close();
        this.toast.showError("Unexpected response code from enrollment : " + enrollment.responseCodeDescription );
        break;
    }
  }

  private handlePendingAuthentication(secondResponse: any): void {
    this.spinner.open()
    console.log('SE REALIZA PROCESO DE 3DS YA QUE ENROLLMENT RETORNO PENDING_AUTHENTICATION');
    this.startPolling();
  
    this.jwtTokenForThird = secondResponse.auth3DSDetail.htmlCode;
    this.transactionIdForThird = secondResponse.transactionId;
    console.log('htmlCode para el 3DS:', this.jwtTokenForThird);
  
    if (secondResponse.extraData.veresEnrolled == "Y" && secondResponse.extraData.paresStatus == "C") {
      console.log("Successful Step-Up Authentication");
    }
  
    this.createAndSubmitStepUpForm(this.jwtTokenForThird);
  }
  
  createAndSubmitStepUpForm(jwtToken: string): void {
    console.log('Iniciando StepUp para 3DS');
  
    const iframe = document.createElement('iframe');
    iframe.name = 'step-up-iframe';
    iframe.height = '900';
    iframe.width = '900';
    iframe.style.display = 'block';
    iframe.style.zIndex = '9999';
    iframe.style.top = '0';
    iframe.style.position = 'fixed';
    iframe.style.background = 'white';
  
    document.body.appendChild(iframe);
    this.iframeElement = iframe; // Guardar referencia al iframe
  
    console.log('Iframe para StepUp creado:', iframe);
  
    const form = document.createElement('form');
    form.id = 'step-up-form';
    form.target = 'step-up-iframe';
    form.method = 'post';
    form.action = 'https://centinelapistag.cardinalcommerce.com/V2/Cruise/StepUp';
  
    const inputJWT = document.createElement('input');
    inputJWT.type = 'hidden';
    inputJWT.name = 'JWT';
    inputJWT.value = jwtToken;
    form.appendChild(inputJWT);
  
    const inputMD = document.createElement('input');
    inputMD.type = 'hidden';
    inputMD.name = 'MD';
    inputMD.value = 'optionally_include_custom_data_that_will_be_returned_as_is';
    form.appendChild(inputMD);
  
    document.body.appendChild(form);
    console.log('Formulario de StepUp creado y agregado al DOM:', form);
  
    form.submit();
    console.log('Formulario de StepUp enviado');
  
    iframe.onload = () => {
      console.log('Iframe StepUp loaded and displayed.');
    };
    this.spinner.close();
    window.addEventListener("message", (event) => {
      if (event.origin === "https://centinelapistag.cardinalcommerce.com") {
        console.log('Mensaje StepUp recibido desde:', event.origin);
        const data = JSON.parse(event.data);
        console.log('Mensaje StepUp recibido:', data);
      }
    }, false);
  }

  private transactionenrollment(additionalData: any = {}): any {
    return {
      creditCard: {
        cardholderName: this.paymentForm.value.nameHolderCreditCard,
        creditCardDate: this.paymentForm.value.expYearCreditCard + this.paymentForm.value.expMonthCreditCard,
        cvv: this.paymentForm.value.ccv
      },
      transactionIdentifier: this.uniqueCodeService.getTransactionIdentifier(),
      currency: "USD",
      extraData: {
        ip: this.myIp,
        fingerprint: this.uniqueCode,
        screenHeight: this.myHeight,
        screenWidth: this.myWidth,
        email: localStorage.getItem('username'),
        ...additionalData
      },
      auth: additionalData.auth
    };
  }

  startPolling(): void {
    this.spinner.open();
    const url = `${this.webService.SECURITY_HOST}/hasplan/${this.hasPlanID}`;
    let polling = true;
  
    interval(3000).pipe(
      takeWhile(() => polling),
      switchMap(() => this.webService.get(url).pipe(
        catchError(error => {
          if (error.status === 404) {
            this.spinner.close();
            console.log('Respuesta 404: No encontrado');
            return of(null);
          } else {
            this.spinner.close();
            console.error('Error en la solicitud:', error);
            return of(null);
          }
        })
      ))
    ).subscribe(response => {
      if (response && response?.result) {
        console.log("RESPUESTA", response);
  
        if (response.result.status_customerPlan == 100) {
          this.spinner.close();
          polling = false;
          this.pollingSuccess.next();
          this.removeIframe();
        }
      }
    });
  }

  private handleEnrollmentSuccess(secondResponse: any): void {
    this.spinner.open();
    const fourthData = this.createTransactionData(secondResponse.transactionId, {
      auth: {
        authenticationTransactionId: secondResponse.transactionId
      },
      eci: secondResponse.extraData.eci,
      eciRaw: secondResponse.extraData.eciRaw,
      cavv: secondResponse.extraData.cavv,
      paresStatus: secondResponse.extraData.paresStatus,
      veresEnrolled: secondResponse.extraData.veresEnrolled,
      xid: secondResponse.extraData.xid,
      authenticationTransactionId: secondResponse.extraData.authenticationTransactionId,
      acsTransactionId: secondResponse.extraData.acsTransactionId,
      ecommerceIndicator: secondResponse.extraData.ecommerceIndicator,
      specificationVersion: secondResponse.extraData.specificationVersion,
      directoryServerTransactionId: secondResponse.extraData.directoryServerTransactionId,
      ucafAuthenticationData: secondResponse.extraData.ucafAuthenticationData,
      ucafCollectionIndicator: secondResponse.extraData.ucafCollectionIndicator
    });

    const json = { chp: this.hasPlanID, sop: fourthData, call: "sale" };
    console.log("CON ESTO HACEMOS EL SALE : ", json)
    this.webService.post(json, `${this.webService.SECURITY_HOST}/sop`).subscribe(fourthResponse => {
      console.log("RESPUESTA DEL SALE: ", fourthResponse)
      this.myLoadEvent();
      this.spinner.close();
    }, errr =>{
      console.error(errr);
      this.toast.showError(errr)
    });
  }


  private createAndSubmitIframeForm(jwtToken: string, actionUrl: string, iframeName: string, formId: string): void {
    const iframe = document.createElement('iframe');
    iframe.name = iframeName;
    iframe.height = '900';
    iframe.width = '900';
    iframe.style.display = 'none';
    document.body.appendChild(iframe);
    this.iframeElement = iframe;

    const form = document.createElement('form');
    form.id = formId;
    form.target = iframeName;
    form.method = 'POST';
    form.action = actionUrl;

    const inputJWT = document.createElement('input');
    inputJWT.type = 'hidden';
    inputJWT.name = 'JWT';
    inputJWT.value = jwtToken;
    form.appendChild(inputJWT);

    document.body.appendChild(form);
    form.submit();

    iframe.onload = () => {
      iframe.style.display = 'block';
    };

    window.addEventListener("message", (event) => {
      if (event.origin === "https://centinelapistag.cardinalcommerce.com") {
        const data = JSON.parse(event.data);
        console.log('Mensaje recibido:', data);
      }
    }, false);
  }

  private removeIframe(): void {
    if (this.iframeElement) {
      this.spinner.close();
      document.body.removeChild(this.iframeElement);
    }
  }

  continueToNextStep() {
    this.spinner.open();
  
    const transactionIdentifier = this.uniqueCodeService.getTransactionIdentifier();
  
    if (!this.jwtTokenForThird || !this.transactionIdForThird) {
      console.error('No se encontró jwtTokenForThird o transactionIdForThird para la tercera petición.');
      this.spinner.close();
      return;
    }
  
    const thirdData = this.createTransactionData(transactionIdentifier, {
      auth: {
        authenticationTransactionId: this.transactionIdForThird,
        signedPares: this.jwtTokenForThird
      }
    });
  
    const validateJson = { chp: this.hasPlanID, sop: thirdData, call: "validate" };
    console.log('REQUEST PARA VALIDATE AUTH', validateJson);
  
    this.webService.post(validateJson, this.webService.SECURITY_HOST + "/sop")
      .pipe(
        switchMap(thirdResponse => this.handleValidateAuthResponse(thirdResponse, transactionIdentifier)),
        catchError(error => this.handleErrorDuringChain(error))
      )
      .subscribe(fourthResponse => this.handleSaleResponse(fourthResponse));
  }
  
  private handleValidateAuthResponse(thirdResponse: any, transactionIdentifier: string): Observable<any> {
    this.spinner.open();
    console.log('Respuesta para VALIDATE AUTH:', thirdResponse);
  
    if (thirdResponse?.responseCodeDescription === "AUTHENTICATION_SUCCESSFUL") {
      console.log("Successful Step-Up Authentication ON VALIDATE AUTH");
  
      const extraData = thirdResponse.extraData;
      const fourthData = this.createTransactionData(transactionIdentifier, {
        auth: {
          authenticationTransactionId: this.transactionIdForThird
        },
        eci: extraData.eci,
        eciRaw: extraData.eciRaw,
        cavv: extraData.cavv,
        authenticationTransactionId: extraData.authenticationTransactionId,
        acsTransactionId: extraData.acsTransactionId,
        paresStatus: extraData.paresStatus,
        veresEnrolled: extraData.veresEnrolled,
        xid: extraData.xid,
        ecommerceIndicator: extraData.ecommerceIndicator,
        specificationVersion: extraData.specificationVersion,
        directoryServerTransactionId: extraData.directoryServerTransactionId,
        ucafAuthenticationData: extraData.ucafAuthenticationData,
        ucafCollectionIndicator: extraData.ucafCollectionIndicator
      });
  
      const saleJson = { chp: this.hasPlanID, sop: fourthData, call: "sale" };
      console.log('REQUEST PARA SALE', saleJson);
  
      return this.webService.post(saleJson, this.webService.SECURITY_HOST + "/sop");
    } else {
      console.log("VALIDATE AUTH RETORNO AUTHENTICATION_FAILED");
      this.toast.showError("AUTHENTICATION_FAILED");
      this.spinner.close();
      return of(null);
    }
  }
  
  private handleErrorDuringChain(error: any): Observable<null> {
    console.error('Error en la cadena de peticiones:', error);
    this.toast.showError(`Error en VALIDATE AUTH: ${error.message}`);
    this.spinner.close();
    return of(null);
  }
  
  private handleSaleResponse(fourthResponse: any): void {
    this.spinner.open();
  
    if (fourthResponse?.responseCodeDescription) {
      console.log('Respuesta del SALE:', fourthResponse);
  
      if (fourthResponse.responseCodeDescription === 'AUTHORIZED') {
        // this.dialogRef.close(1);
        this.myLoadEvent();
      } else {
        this.spinner.close();
        this.toast.showError("El pago no se procesó correctamente. Por favor, intente nuevamente.");
      }
    } else {
      this.spinner.close();
      console.log('SALE no fue exitoso o no se ejecutó debido a un error previo.');
      this.toast.showError("No se obtuvo una respuesta del proceso de pago. Verifique la transacción o intente nuevamente. " + fourthResponse.responseCodeDescription);
    }
  
    this.spinner.close();
  }

  createTransactionData(transactionIdentifier: string = '', additionalData: any = {}): any {
    return {
      creditCard: {
        cardholderName: this.paymentForm.value.nameHolderCreditCard,
        creditCardDate: `${this.paymentForm.value.expYearCreditCard}${this.paymentForm.value.expMonthCreditCard}`,
        cvv: this.paymentForm.value.ccv
      },
      transactionIdentifier: transactionIdentifier,
      currency: "USD",
      extraData: {
        ip: this.myIp,
        fingerprint: this.uniqueCode,
        screenHeight: this.myHeight,
        screenWidth: this.myWidth,
        email: localStorage.getItem('username'),
        street: this.paymentForm.value.street,
        city: this.paymentForm.value.city,
        postalCode: this.paymentForm.value.postalCode,
        phone: this.paymentForm.value.phone,
        ...additionalData
      },
      auth: additionalData.auth
    };
  }

  // TERMINA PROCESOS CYBERSOURCE

  // EMETEC PROCESOS

  private handleEmetecResponse(response: any): void {
    this.dataObject = this.buildRequestData();
    this.hasPlanID = response.id;

    if (response.sopResponse.responseCodeDescription === "transaction pending") {
      this.handleEmetecPendingTransaction(response);
    } else {
      const updatedData = this.transactionEmetec();
      updatedData.transactionIdentifier = response.sopResponse.transactionId;
      const json = { chp: response.id, sop: updatedData, call: "payment" };
      this.getEmetecPayment(json);
    }
  }

  getEmetecPayment(json: any): void {
    this.spinner.open();
    console.log("USANDO ESTA DATA HACEMOS EL PAYMENT: ", json);
  
    if (this.retryCount < this.maxRetries) {
      this.retryCount++;
      timer(3000).pipe(
        switchMap(() => this.webService.post(json, `${this.webService.SECURITY_HOST}/sop`)),
        takeWhile(() => this.retryCount <= this.maxRetries)
      ).subscribe(response => {
        console.log("RETRY RESPONSE PAYMENT: ", response);
  
        if (response.responseCodeDescription === "Transaction succeeded") {
          this.spinner.open();
          this.myLoadEvent();
        } else if (response.responseCodeDescription === "transaction pending") {
          // Continue retrying
          this.getEmetecPayment(json);
        } else {
          this.spinner.close();
          this.toast.showError("Hubo un error en el proceso: " + response.responseCodeDescription);
        }
      }, err => {
        console.error("ERROR: ", err);
        this.spinner.close();
        this.toast.showError("Error en el proceso de pago. Intente nuevamente.");
      });
    } else {
      this.spinner.close();
      this.toast.showError("Se alcanzó el número máximo de intentos. Transacción sigue en estatus pending. Contacte con el administrador.");
    }
  }

  private handleEmetecPendingTransaction(response: any): void {
    this.spinner.open();
    // Store transaction details from the response
    this.transactionId = response.sopResponse.transactionId;
    this.iframeValue = response.sopResponse.auth3DSDetail.iframeValue;
    this.iframeName = response.sopResponse.auth3DSDetail.iframeName;
    this.iframeUrl = response.sopResponse.auth3DSDetail.iframeUrl;
    this.redirectUrl = response.sopResponse.auth3DSDetail.redirectUrl;
  
    console.log('Datos almacenados:');
    console.log('transactionId:', this.transactionId);
    console.log('iframeValue:', this.iframeValue);
    console.log('iframeName:', this.iframeName);
    console.log('iframeUrl:', this.iframeUrl);
    console.log('redirectUrl:', this.redirectUrl);
  
    // Send POST request using fetch with no-cors for the first iframe
    this.sendIframeRequest(this.iframeUrl, this.iframeValue)
      .then(() => {
        console.log('Solicitud del primer iframe enviada con éxito');
        this.spinner.close(); // Stop the loader before opening the popup
        
        // Open the second iframe in a popup
        this.openInteractiveIframePopup(this.redirectUrl, this.iframeValue, response.sopResponse.transactionId);
      })
      .catch(err => {
        console.error('Error al enviar la solicitud del primer iframe:', err);
        this.toast.showError('Error al enviar la solicitud del primer iframe:' + err);
        this.spinner.close();
      });
  }

  openInteractiveIframePopup(url: string, iframeValue: string, transactionID: string): void {
    // const dialogRef = this.dialog.open(InteractiveIframeDialog, {
    //   width: '600px',
    //   data: { url, iframeValue }
    // });
    //
    // // Store the transaction identifier in the data object
    // this.dataObject.transactionIdentifier = transactionID;
    //
    // dialogRef.afterClosed().subscribe(result => {
    //   console.log('Interactive iframe popup closed');
    //   const json = { chp: this.hasPlanID, sop: this.dataObject, call: "payment" };
    //   this.getEmetecPayment(json);
    // });
  }

  private buildRequestData(): any {
    const baseData = {
      creditcard: {
        numberCreditCard: this.paymentForm.value.number_tdc.trim(),
        nameHolderCreditCard: this.paymentForm.value.name_tdc,
        expMonthCreditCard: this.paymentForm.value.month,
        expYearCreditCard: this.paymentForm.value.year,
        ccv: this.paymentForm.value.ccv
      },
      extraData: {
        ip: this.myIp,
        fingerprint: this.uniqueCode,
        screenHeight: this.myHeight,
        screenWidth: this.myWidth,
        email: localStorage.getItem('username'),
        street: this.paymentForm.value.street,
        city: this.paymentForm.value.city,
        postalCode: this.paymentForm.value.postalCode,
        phone: this.paymentForm.value.phone
      }
    };
  
    return baseData;
  }

  async sendIframeRequest(url: string, iframeValue: string): Promise<void> {
    // Create the request body in URL-encoded format
    const body = new URLSearchParams();
    body.append('threeDSMethodData', iframeValue);
  
    console.log('Datos que se envían al iframe:');
    console.log('URL:', url);
    console.log('Datos del formulario:', body.toString());
  
    // Send the POST request to the iframeUrl
    try {
      let response: Response;
      let attempts = 0;
      const maxAttempts = 5;
      const delay = 1000; // 1 second
  
      do {
        response = await fetch(url, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
          body: body.toString(),
          mode: 'no-cors', // Add no-cors mode
          credentials: 'include'
        });
  
        if (response.status === 200) {
          console.log('Solicitud iframe enviada con éxito.');
          break;
        } else {
          console.error(`Intento ${attempts + 1}: Error en la solicitud iframe:`, response.status, response.statusText);
        }
  
        attempts++;
        if (attempts < maxAttempts) {
          await new Promise(resolve => setTimeout(resolve, delay)); // Wait 1 second before the next attempt
        }
      } while (attempts < maxAttempts);
  
      if (attempts === maxAttempts && response.status !== 200) {
        console.error('Se alcanzó el número máximo de intentos y la solicitud no fue exitosa.');
      }
    } catch (error) {
      console.error('Error al enviar la solicitud del primer iframe:', error);
    }
  }

  private transactionEmetec(): any {
    return {
      creditCard: {
        cardholderName: this.paymentForm.value.nameHolderCreditCard,
        creditCardDate: this.paymentForm.value.expYearCreditCard + this.paymentForm.value.expMonthCreditCard,
        cvv: this.paymentForm.value.ccv
      },
      transactionIdentifier: this.uniqueCodeService.getTransactionIdentifier(),
      currency: "USD",
      extraData: {
        ip: this.myIp,
        fingerprint: this.uniqueCode,
        screenHeight: this.myHeight,
        screenWidth: this.myWidth,
        email: localStorage.getItem('username'),
        street: this.paymentForm.value.street,
        city: this.paymentForm.value.city,
        postalCode: this.paymentForm.value.postalCode,
        phone: this.paymentForm.value.phone
      }
    };
  }

  // TERMINA PROCESOS PARA EMETEC

  search(){
    this.spinner.open();
    // Limpiar campos específicos antes de la búsqueda
    this.clientForm.patchValue({
      name_customer: '',
      lastName_customer: '',
      identifier_customer: '',
      phoneNumber_customer: ''
    });

    this.webService.get(this.webService.SECURITY_HOST + "/partner/" +this.clientForm.value.partner.id + "/customer/code/" + this.clientForm.value.email_customer).subscribe(response=>{

      if(response.result != null && response.result.id != null){
        this.toast.showSuccess("Cliente encontrado,datos cargados.")
        this.clientForm.patchValue({
          name_customer: response.result.name_customer,
          lastName_customer: response.result.lastName_customer,
          identifier_customer: response.result.identifier_customer,
          email_customer: response.result.email_customer,
          phoneNumber_customer: response.result.phoneNumber_customer
        });
      }else {
        console.log(response);
        this.toast.showError("No se encontró un cliente con este email")
      }
      this.spinner.close();
    }, error => {
      this.spinner.close();
      this.toast.showError(error.message)
      console.log(error);
    })

  }



}


// @Component({
//   selector: 'interactive-iframe-dialog',
//   template: `
//     <h1 mat-dialog-title>Complete la verificación</h1>
//     <div mat-dialog-content>
//       <iframe [src]="sanitizer.bypassSecurityTrustResourceUrl(data.url)" width="100%" height="400px" sandbox="allow-forms allow-pointer-lock allow-popups allow-same-origin allow-scripts"></iframe>
//     </div>
//     <div mat-dialog-actions>
//       <button mat-button (click)="onClose()">Cerrar</button>
//     </div>
//   `,
//   styles: []
// })
// export class InteractiveIframeDialog {
//   constructor(
//     public dialogRef: MatDialogRef<InteractiveIframeDialog>,
//     @Inject(MAT_DIALOG_DATA) public data: { url: string },
//     public sanitizer: DomSanitizer
//   ) {}
//
//   onClose(): void {
//     this.dialogRef.close();
//     console.log("AQUI DEBERIA IR EL SALE")
//   }
// }
