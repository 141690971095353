import { Component } from '@angular/core';
import {SecurityService} from "../../services/security.service";

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent {
  isIconRotated: boolean = false;

  constructor(private securityService : SecurityService) { }

  rotateIcon() {
    // console.log("Aqui")
    this.isIconRotated = !this.isIconRotated;
  }
  
  resetIcon() {
    // console.log("Aqui2")
    this.isIconRotated = false;
  }

  goLogin(){
    this.securityService.logout();
  }

}
