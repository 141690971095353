import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Validators, FormGroup, FormControl } from '@angular/forms';
import { WebService}  from '../services/web/web.service';
import { ToastService } from '../services/notification/toast.service';
import {SpinnerService} from '../services/spinner/spinner.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import { HttpParams} from '@angular/common/http';


@Component({
  selector: 'app-create-account',
  templateUrl: './create-account.component.html',
  styleUrls: ['./create-account.component.scss']
})
export class CreateAccountComponent {

  signUpForm: FormGroup ;

  constructor(private router : Router,private spinner : SpinnerService,private webService : WebService,private toast : ToastService, private auth : AuthService) {
    this.signUpForm = new FormGroup({
      email: new FormControl('', [Validators.required,Validators.email])
    });
  }

  ngOnInit(): void {
  }

  singUp() {
    if (this.signUpForm.valid) {
      this.spinner.open();
      const signinData = this.signUpForm.value;

      this.webService.post(signinData, this.webService.SECURITY_HOST + "/billcentrix/V2/user").subscribe(res => {
        this.spinner.close();
        this.toast.showSuccess("Ha creado su cuenta correctamente, se enviará una clave a su correo, por favor inicie sesión con sus credenciales...");
        this.router.navigateByUrl("/login");
      }, err => {
        this.spinner.close();
        console.log(err);
        this.toast.showError(err); // Asegúrate de que esto es lo que quieres mostrar
      });
    } else {
      // Muestra una alerta si el formulario no es válido
      this.toast.showError("Debe ingresar un correo válido.");
    }
  }
  onLoginClick() {
    this.router.navigate(["/login"])
    
  }
  goBack(){
    this.router.navigate(['']);
  }
  goHome() {
    this.router.navigate(['/']);
  }
}
