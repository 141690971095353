import { Component, OnInit, ViewChild,ChangeDetectorRef } from '@angular/core';
import { Validators, FormGroup, FormControl } from '@angular/forms';
import { WebService}  from '../services/web/web.service';
import { ToastService } from '../services/notification/toast.service';
import {SpinnerService} from '../services/spinner/spinner.service';
import {ActivatedRoute, Router} from '@angular/router';
import { Location } from '@angular/common';
import { PortalService } from '../account-settings/portal/my-portals/portal.service';
import { HttpClient } from '@angular/common/http';


@Component({
  selector: 'app-customer-address-update',
  templateUrl: './customer-address-update.component.html',
  styleUrls: ['./customer-address-update.component.scss']
})
export class CustomerAddressUpdateComponent implements OnInit{

  form: FormGroup ;
  provinces: any[]= [] ;
  corregimientos: any[]= [] ;
  customer : any;

  constructor(private http : HttpClient,private location: Location ,private portalService : PortalService,
    private activateRoute : ActivatedRoute,private router : Router,private spinner : SpinnerService,private webService : WebService,
    private toast : ToastService) {
    this.form = new FormGroup({
        id: new FormControl('', Validators.required),
        phone: new FormControl('', Validators.required),
        adress: new FormControl('', Validators.required),
        province : new FormControl('', Validators.required),
        corregimiento : new FormControl('', Validators.required)
    }); 
  }

  ngOnInit(): void {
this.activateRoute.params.subscribe(params =>{
  this.customer = params['id'];
  this.webService.get(this.webService.SECURITY_HOST + "/adress/" +  params['address']).subscribe(address =>{
    this.spinner.close();
    this.form.controls['id'].setValue(address.result.id);
    this.form.controls['province'].setValue(address.result.province.id);
    this.form.controls['corregimiento'].setValue(address.result.corregimiento.id);
    this.form.controls['adress'].setValue(address.result.adress);
    this.form.controls['phone'].setValue(address.result.phone);
    this.webService.get(this.webService.SECURITY_HOST + "/province").subscribe( provinces =>{
          
      this.provinces = provinces.result;
      this.onProvinceFirstChange(this.form.value.province);

    }, err =>{
      this.spinner.close();
      this.toast.showError(err);
    });
  },err =>{
    console.log(err);
    this.spinner.close();
  });
}, err =>{
  console.log(err);
});
    

  }

  onProvinceFirstChange(province:any){
    this.corregimientos = [];
    if(this.provinces[this.provinces.findIndex(t => t.id == province)] != null){
      this.corregimientos = this.provinces[this.provinces.findIndex(t => t.id == province)].corregimientos;
    }
  }


  goBack(){
    this.location.back();
  }

  update(){
    const data = this.form.value;
    data.customer = { id: +this.customer } ;  
    data.status = 1;
    data.corregimiento = {id: +data.corregimiento}
    data.province = {id: +data.province};

    this.spinner.open();
    this.webService.put(data, this.webService.SECURITY_HOST + "/adress/" + data.id ).subscribe(res =>{
        this.toast.showSuccess("Actualizada correctamente");
        this.spinner.close();
        this.location.back();
    }, err=>{
      this.toast.showError(err);
    });
    
  }

  onProvinceChange(){

    this.corregimientos = [];
    this.corregimientos = this.provinces[this.provinces.findIndex(t => t.id == Number(this.form.value.province) )].corregimientos;
    this.corregimientos.sort((a,b) =>  (a.name > b.name ? 1 : -1));

  }

  omit_special_char(event: { charCode: any; }){   
    var k;  
    k = event.charCode;  //         k = event.keyCode;  (Both can be used)
    return((k > 64 && k < 91) || (k > 96 && k < 123) || k == 8 || k == 32 || (k >= 48 && k <= 57)); 
   }

   onlyNumberKey(event : any){
    return (event.charCode == 8 || event.charCode == 0) ? null : event.charCode >= 48 && event.charCode <= 57;
  }


}
