import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import {WebService} from "./web/web.service";

@Injectable({
  providedIn: 'root'
})
export class SecurityService {

  private tokenKey = 'authToken';
  private refreshKey = 'refresToken';// Clave para guardar el token en el sessionStorage
  private pinValidatedKey = 'pinValidated';
  private loginUrl = this.webService.SECURITY_HOST + '/auth/login';  // URL del backend para autenticación
  private refreshUrl = this.webService.SECURITY_HOST + '/auth/refresh';


  constructor(private webService : WebService,private http: HttpClient, private router: Router) { }

  login(username: string, password: string): Observable<any> {
    return this.http.post<any>(this.loginUrl, { username : username, password : password, partner : null}).pipe(
        map(response => {
          if (response.jwt) {
            this.saveToken(response.jwt);
            this.saveRefresh(response['refreshToken'])// Guardar el token
          }
          return response;
        }),
        catchError(this.handleError)
    );
  }

  refreshToken(): Observable<any> {
    // Genera un número aleatorio entre 100000 y 999999
    const randomPassword = Math.floor(Math.random() * 900000) + 100000;
    return this.http.post<any>(this.refreshUrl, { username : this.getTokenSub(), password : randomPassword, partner : null}).pipe(
        map(response => {
          if (response.jwt) {
            this.saveToken(response.jwt);  // Guardar el token
          }
          return response;
        }),
        catchError(this.handleError)
    );
  }

  // Guardar el token en sessionStorage
  saveToken(token: string): void {
    sessionStorage.setItem(this.tokenKey, token);
  }

  // Obtener el token desde sessionStorage
  getToken(): string | null {
    return sessionStorage.getItem(this.tokenKey);
  }


  // Validar si el token está expirado
  isTokenExpired(): boolean {
    const token = this.getToken();
    const isPinValid = this.getPinValidationStatus();

    if (!token) return true;
    if (!isPinValid) return true;

    const payload = JSON.parse(atob(token.split('.')[1]));
    const currentTime = Math.floor(new Date().getTime() / 1000);  // Tiempo actual en segundos

    return payload.exp < currentTime;
  }

  // Nueva función para guardar el estado de validación del PIN
  isPinValidated(validated: boolean): void {
    sessionStorage.setItem(this.pinValidatedKey, validated.toString());
  }

  // Función para obtener el estado de validación del PIN
  getPinValidationStatus(): boolean {
    return sessionStorage.getItem(this.pinValidatedKey) === 'true';
  }

  private decodeTokenPayload(): any | null {
    const token = this.getToken();
    if (!token) return null;

    try {
      const payload = JSON.parse(atob(token.split('.')[1]));
      return payload;
    } catch (error) {
      console.error('Failed to decode token payload:', error);
      return null;
    }
  }

  getTokenConfigs(): any | null {
    const payload = this.decodeTokenPayload();
    return payload ? payload.config : null;
  }

  getTokenPartner(): any | null {
    const payload = this.decodeTokenPayload();
    return payload ? payload.partner : null;
  }

  getTokenSub(): any | null {
    const payload = this.decodeTokenPayload();
    return payload ? payload.sub : null;
  }

  getTokenCustomer(): any | null {
    const payload = this.decodeTokenPayload();
    return payload ? payload.customer : null;
  }

  getTokenUser(): any | null {
    const payload = this.decodeTokenPayload();
    return payload ? payload.user : null;
  }

  getRefreshToken(): any | null {
    return sessionStorage.getItem(this.refreshKey);
  }

  // Método para cerrar sesión
  logout(): void {
    sessionStorage.removeItem(this.tokenKey);
    sessionStorage.removeItem(this.pinValidatedKey);
    this.router.navigate(['/login']);
  }

  // Manejo de errores
  private handleError(error: HttpErrorResponse) {
    let errorMsg = 'Error desconocido';
    if (error.error instanceof ErrorEvent) {
      errorMsg = `Error: ${error.error.message}`;
    } else {
      errorMsg = `Error: ${error.status}, ${error.message}`;
    }
    return throwError(() => new Error(errorMsg));
  }

  public getDecodedToken(): any | null {
    const token = this.getToken();
    if (!token) return null;

    try {
      // Split the token into its parts: header, payload, signature
      const parts = token.split('.');
      if (parts.length !== 3) return null;

      // Decode each part
      const decodedToken = {
        header: JSON.parse(atob(parts[0])),
        payload: JSON.parse(atob(parts[1])),
        // Note: signature is kept in its original format as it can't be decoded
        signature: parts[2]
      };

      return decodedToken;
    } catch (error) {
      console.error('Error decoding token:', error);
      return null;
    }
  }

  private saveRefresh(refresh:any) {
    sessionStorage.setItem(this.refreshKey, refresh);
  }
}
