import { Injectable } from "@angular/core";
import {AuthService} from '../services/auth/auth.service';
import { ToastService } from '../services/notification/toast.service';
import { Location } from '@angular/common';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
} from "@angular/router";
import { WebService}  from '../services/web/web.service';
import {SpinnerService} from '../services/spinner/spinner.service';
import {SecurityService} from "../services/security.service";


@Injectable()
export class AuthGuard implements CanActivate {

  constructor(private router: Router, private authService: SecurityService,private toast : ToastService,private location : Location,private spinner : SpinnerService,private webService : WebService) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

    let token = this.authService.getToken();

    if (token && !this.authService.isTokenExpired()) { // LO DEJAMOS AVANZAR SI ESTA LOGEADO Y SI NO SE LE HA VENCIDO LA SESION, TIENE PERMISO DE ACANZAR
      return true;
    } else { // CASO CONTRARIO LO MANDAMOS A INICIAR SESION NUEVAMENTE
      this.router.navigate(["/unauthorized"], {
        queryParams: {
          return: state.url
        }
      });
      return false;
    }

  }
}
