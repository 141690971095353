<button mat-icon-button [matMenuTriggerFor]="menu" class="transparent" (click)="rotateIcon()" (menuClosed)="resetIcon()">
    <img src="../../assets/icons/menu-hamb.png" alt="menu hamburguesa" [ngClass]="{'rotate-left': isIconRotated}">
</button>

<mat-menu #menu="matMenu" class="menu">
    <!-- Contenido del menú -->
    <button mat-menu-item>
        <a [routerLink]="['/preferences']">
            <img src="../../assets/icons/preferencias.png" alt="preferencias">
            <span>Preferencias</span>
        </a>
    </button>
    <!-- <button mat-menu-item>
        <a [routerLink]="['/plans']">
            <img src="../../assets/icons/etiqueta.png" alt="planes">
            <span>Planes</span>
        </a>
    </button> -->
    <button mat-menu-item>
        <a [routerLink]="['/my-portals']">
            <img src="../../assets/icons/cuadra-white.png" alt="portales">
            <span>Suscripciones</span>
        </a>
    </button>
    <button mat-menu-item>
        <a [routerLink]="['/reports']">
            <img src="../../assets/icons/grafico.png" alt="reportes">
            <span>Clientes</span>
        </a>
    </button>
    <button mat-menu-item>
        <a [routerLink]="['/configurations']">
            <img src="../../assets/icons/grafico.png" alt="reportes">
            <span>Configuraciones</span>
        </a>
    </button>
    <!-- <button mat-menu-item>
        <a [routerLink]="['/faqs']">
            <img src="../../assets/icons/informacion.png" alt="FAQs">
            <span>FAQs</span>
        </a>
    </button> -->
    <button mat-menu-item>
        <a (click)="goLogin()">
            <img src="../../assets/icons/salida.png"  alt="salir">
            <span>Salir</span>
        </a>
    </button>
</mat-menu>