import { Injectable } from "@angular/core";
import { HttpHeaders } from '@angular/common/http';
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest
} from "@angular/common/http";
import { Observable } from "rxjs";
import {SecurityService} from "../services/security.service";

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

  constructor(private securityService: SecurityService) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {

    // Excluir el servicio de autenticación (login)
    if (req.url.includes("/resend-pin") || req.url.includes("/billcentrix/V2/resetPassword") || req.url.includes("/billcentrix/V2/user") || req.url.includes("/login") || req.url.includes("/token") || req.url.includes("/log") || req.url.includes("/validate")) {
      return next.handle(req);  // No se modifica la solicitud si es la URL de login
    }
    
    var changedReq;
    // BUSCAMOS EL TOKEN DE ACCESO DEL USUARIO
    if(this.securityService.getToken() != null){
      var token = this.securityService.getToken();
     
      var user = Number(this.securityService.getTokenUser()) ;

      changedReq = req.clone({
        headers: new HttpHeaders({
          'Authorization': `Bearer ${token}`,
          'User': user.toString(),
        })
      });

    }else{
      changedReq = req;
    }
   
    return next.handle(changedReq);
  }
}
