import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Validators, FormGroup, FormControl } from '@angular/forms';
import { WebService}  from '../services/web/web.service';
import { ToastService } from '../services/notification/toast.service';
import {SpinnerService} from '../services/spinner/spinner.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import {SecurityService} from "../services/security.service";


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent {

  signinForm: FormGroup ;
  passwordFieldType: string = 'password';
  constructor(private securityService : SecurityService, private router : Router,private spinner : SpinnerService,private webService : WebService,private toast : ToastService, private auth : AuthService) {
    this.signinForm = new FormGroup({
      username: new FormControl('', [Validators.required,Validators.email]),
      password: new FormControl('', Validators.required)
    });
   
   }
   
   ngOnInit(): void {
   }
   togglePasswordVisibility() { // Nuevo método para alternar la visibilidad de la contraseña
    this.passwordFieldType = this.passwordFieldType === 'password' ? 'text' : 'password';
  }
   signin() {

    this.spinner.open();

    const signinData = this.signinForm.value;

        this.securityService.login(signinData.username, signinData.password).subscribe( securityResponse =>{
            this.spinner.close();
            // this.toast.showSuccess("Ha iniciado sesión correctamente");
            // this.router.navigate(['/my-portals']);
            this.router.navigate(['/validate']);

        }, error => {
            this.spinner.close();
            console.log(error);
            this.toast.showError(error);
        })


  }

  goRegistry(){
    this.router.navigateByUrl("/create");
  }
  goHome() {
    this.router.navigate(['/']);
  }
  
}
