import { Component, Input, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {PlanService} from "../../account-settings/plans/plan.service";
import {WebService} from "../../services/web/web.service";
import {ToastService} from "../../services/notification/toast.service";
import {SpinnerService} from "../../services/spinner/spinner.service";


@Component({
  selector: 'app-plan',
  templateUrl: './plan.component.html',
  styleUrls: ['./plan.component.scss']
})
export class PlanComponent {
  public myplans : any = [];
  @Input() plan: any;
  imagePath: any;

  constructor(private toast : ToastService,private spinner : SpinnerService,private webService : WebService ,private router : Router, private activateRoute : ActivatedRoute,private planService : PlanService) { }

  ngOnInit(): void {
     this.imagePath = this.plan.url_image ? this.plan.url_image : '../../../assets/icons/photo.png';

  }

  goEdit(){
     this.router.navigate(['update', this.plan.id], {relativeTo : this.activateRoute ,queryParamsHandling : "preserve"}); // ENVIAMOS AL CLIENTE PARA EL STEP2
  }

  onUpdateStatus(id : number, value : number){

    if(value == 1){
      this.activatePlan(id)
    }else{
      this.deactivatePlan(id)
    }

  }

  activatePlan(id : number){
    this.spinner.open();
    this.webService.put({}, this.webService.SECURITY_HOST + "/billcentrix/V2/plan/" + id).subscribe( response =>{
      this.spinner.close();
      this.toast.showSuccess("Plan activado correctamente");
      window.location.reload();
    }, err =>{
      this.spinner.close();
      this.toast.showError(err)
    });

  }

  deactivatePlan(id : number){
    this.spinner.open();
    this.webService.delete(this.webService.SECURITY_HOST + "/billcentrix/V2/plan/" + id).subscribe( response =>{
      this.spinner.close();
      this.toast.showSuccess("Plan desactivado correctamente");
      window.location.reload();
    }, err =>{
      this.spinner.close();
      this.toast.showError(err)
    })
  }
}
