import { Component, Input,OnChanges, SimpleChanges } from '@angular/core';
import {PortalService} from "../../account-settings/portal/my-portals/portal.service";
import {ActivatedRoute, Router} from "@angular/router";
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';
import { PaymentPopupComponent } from 'src/app/account-settings/plans/payment-popup/payment-popup.component';
import { ToastService } from 'src/app/services/notification/toast.service';
import { WebService } from 'src/app/services/web/web.service';
import { SpinnerService } from 'src/app/services/spinner/spinner.service';
import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';




@Component({
  selector: 'app-my-portal',
  templateUrl: './my-portal.component.html',
  styleUrls: ['./my-portal.component.scss']
})
export class MyPortalComponent implements OnChanges{
  public myportals : any = [];
  @Input() portal: any;
  configID : any;
  isPortalActive : boolean = true;
  iframeSrc!: SafeResourceUrl;
  showPaymentButton : Boolean = false;
  constructor(
    private webService : WebService,
    private spinner : SpinnerService,
    private toast : ToastService,
    private sanitizer: DomSanitizer,
    private dialog: MatDialog,
    private router : Router, 
    private activateRoute : ActivatedRoute,
    private portalService : PortalService) {
    
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['portal']) {
      this.handleIsActiveChange();
      if (this.portal && this.portal.url_template) {
        this.iframeSrc = this.getUrlTemplatePreview(this.portal.url_template);
      }
    }
    if (changes['portal'] && this.portal && this.portal.url_template) {
      this.iframeSrc = this.getUrlTemplatePreview(this.portal.url_template);
    }
  }
  onButtonClick(portal: any): void {
    if (portal.customerHasPlan == null || portal.customerHasPlan.status_customerPlan != 1) {
      this.toast.showError('Para continuar, es necesario activar y pagar el servicio. Por favor, haga click en el botón de activar suscripcion para habilitar todas las funcionalidades de tu cuenta');
    } else {
      this.goAddClient(portal);
    }
  }
  ngOnInit(): void {
    
    this.portalService.portals.subscribe( portals =>{
      this.myportals = [];
      this.myportals.push(...portals);
     
    });

    this.isPortalActive = this.portal.isActive;

  }
  private handleIsActiveChange(): void {
    if (this.portal?.isActive !== undefined) {
     
    }
  }

  goEdit(portal : any){
    this.router.navigate(["/edit/portal/" + portal.id], {relativeTo : this.activateRoute, queryParamsHandling : "preserve"});
  }
  goAddClient(portal : any){
    this.router.navigate(["/portal/" + portal.id + "/client"], {relativeTo : this.activateRoute, queryParamsHandling : "preserve"});
  }

  goToPortal(portal : any) {
    window.open(portal, '_blank');
  }

  getUrlTemplatePreview(url: string): SafeResourceUrl {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }


  goToTemplates(portal : any, update : Boolean){
    if(update){
      this.router.navigate(["/edit/portal/" +portal.id+'/portal'], {queryParams: { update: true }}); 
    }else{
      this.router.navigate(["/edit/portal/" +portal.id+'/portal']); 
    }
    
  }
  goToFeatures(portal : any){
    this.router.navigate(["/edit/portal/" +portal.id+'/features'], {queryParams: { update: true }}); 
  }

  goDeactivatePortal(portal: any){
    let dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '600px',
    
    });
  
    dialogRef.afterClosed().subscribe(confirmed => {
      if (confirmed) {
        this.spinner.open();
        const data = { id: portal.id, type: 2 };
        this.webService.post(data, this.webService.SECURITY_HOST + "/onboarding/manageStatus").subscribe(response => {
          this.spinner.close();
          this.toast.showSuccess("Portal desactivado correctamente");
          window.location.reload();
        }, err => {
          this.spinner.close();
          console.log(err);
          this.toast.showError(err);
        });
      } else {
        this.spinner.close();
      }
    });
  }
  

  processPayment(portal : any){
    let dialogRef: MatDialogRef<any> = this.dialog.open(PaymentPopupComponent, {
      width: '100%',
      disableClose: true,
      data: {
        configID: portal.id,
        type : 1
      }
    })
    dialogRef.afterClosed()
      .subscribe(res => {
        if(res != undefined &&  res == 1){// SOLO SI ES COBRADO
          this.router.navigateByUrl("/congratulation");
        }else{
          window.location.reload();
        }
      })
  }

}
