import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { BasicComponent } from 'src/app/plans-preview/basic/basic.component';
import { Overlay } from '@angular/cdk/overlay';
import { PracticalComponent } from 'src/app/plans-preview/practical/practical.component';
import { ContempoComponent } from 'src/app/plans-preview/contempo/contempo.component';
import { ToastService } from '../../services/notification/toast.service';
import { WebService}  from '../../services/web/web.service';
import {SpinnerService} from '../../services/spinner/spinner.service';
import {ActivatedRoute, Router} from '@angular/router';
import { Validators, FormGroup, FormControl } from '@angular/forms';
import { DomSanitizer} from '@angular/platform-browser';
import {PreviewPopupComponent} from "./preview-popup/preview-popup.component";
import { PlanService } from '../plans/plan.service';
import {SecurityService} from "../../services/security.service";

@Component({
  selector: 'app-portal-customization',
  templateUrl: './portal-customization.component.html',
  styleUrls: ['./portal-customization.component.scss']
})
export class PortalCustomizationComponent {
  dialogRef!: MatDialogRef<PracticalComponent>;
  selectedTheme: string = 'Práctico';
  selectedPalette: any = 'Billcentrix';

  checked : any;
  pallete1 = false;
  pallete2 = false;
  pallete3 = false;
  pallete4 = false;
  exist = false;

  alreadyHavePlans : boolean = false;

  showPreview = false;
  urlClientPreview : any;

  logoName = null;
	logo64: any;
	logoType: any;
  principalName: string = '';
	//principalName = null;
	principal64: any;
	principalType: any;

  promotionalName: string = '';
  //promotionalName = null;
	promotional64: any;
	promotionalType: any;

  clickOnPreview = false;

  form: FormGroup ;
  onboardingConfigID: any;
  public myplans : any = [];
  showPaymentButton : Boolean = false;

  step:any;

  constructor(private securityService: SecurityService,private planService : PlanService,private dialog: MatDialog,private activateRoute : ActivatedRoute,private sanitizer: DomSanitizer,private router : Router,private toast : ToastService,private spinner : SpinnerService,private webService : WebService,
    private overlay: Overlay) {

    this.form = new FormGroup({
      id: new FormControl(''),
      page_title: new FormControl('', Validators.required),
      description_page: new FormControl('', Validators.required),
      price_title : new FormControl('', Validators.required),
      banner_title: new FormControl('', Validators.required),
      description_banner : new FormControl('', Validators.required)

    });

  }

  ngOnInit(): void {

    this.activateRoute.queryParams.subscribe(queryParams =>{
     
      if(queryParams['config']){
        this.onboardingConfigID = queryParams['config'];
      }else{
        if(this.securityService.getTokenConfigs() !=  null){
          this.spinner.open();
          this.exist = true;
          if(this.securityService.getTokenConfigs().length == 1){
            this.onboardingConfigID = this.securityService.getTokenConfigs()[0];
          }

        }
      }

      this.getConfigByID(this.onboardingConfigID);

    }, err =>{
      this.toast.showError(err);
    });

  }

  getConfigByID( id : any){

    // BUSCAMOS TODOS LOS BANCOS
    let partner = -1;
    if(this.securityService.getTokenPartner() != null || this.securityService.getTokenPartner() != undefined){
      partner = this.securityService.getTokenPartner()
    }

    this.webService.get(this.webService.SECURITY_HOST + "/onboardingConfig/" + id + "?partner=" + partner).subscribe( config =>{
      this.step = config.result.service.current_onboarding_step;
      let theme = config.result.url_template;
      this.changeTheme(theme);
      this.exist = true;
      this.urlClientPreview = config.result.url_template + "/preview/" + config.result.service.id;

      if(config.result.color == "Billcentrix"){
        this.checked = "pal1" ;
        this.pallete1 = true;
        this.selectedPalette = 'Billcentrix';
      }else if(config.result.color == "Moderna"){
        this.checked = "pal2" ;
        this.pallete2 = true;
        this.selectedPalette = 'Moderna';
      }else if(config.result.color == "Violeta"){
        this.checked = "pal3" ;
        this.pallete3 = true;
        this.selectedPalette = 'Violeta';
      }else{
        this.checked = "pal1" ;
        this.pallete1 = true;
        this.selectedPalette = 'Billcentrix';
      }

      if(config.result.plans.length >= 1){
        this.planService.plans.emit(config.result.plans);
        this.alreadyHavePlans = true;
      }

      if(config.result.onboardingPortalContent != null){
        this.form.controls["id"].setValue(config.result.onboardingPortalContent.id);
        this.form.controls["page_title"].setValue(config.result.onboardingPortalContent.page_title);
        this.form.controls["description_page"].setValue(config.result.onboardingPortalContent.description_page);
        this.form.controls["price_title"].setValue(config.result.onboardingPortalContent.price_title);
        this.form.controls["banner_title"].setValue(config.result.onboardingPortalContent.banner_title);
        this.form.controls["description_banner"].setValue(config.result.onboardingPortalContent.description_banner);
      }

      if(config.result.onboardingImages != null){
        this.logo64 = config.result.onboardingImages.logo;
        this.principal64 = config.result.onboardingImages.principal_banner;
        this.promotional64 = config.result.onboardingImages.promotional_banner;
      }
      
      this.spinner.close();

      // if(config.result.service.current_onboarding_step >= 5){
  if(config.result.customerHasPlan != null ){
    if(config.result.customerHasPlan.status_customerPlan == 1){
      console.log('Status of customerHasPlan:', config.result.customerHasPlan.status_customerPlan);
      var nextBillDate = new Date(config.result.customerHasPlan.next_bill_date);
      var today = new Date();
      var isValidDate = nextBillDate > today;
      if(!isValidDate){
        this.showPaymentButton = true;
      }
    }else{
      this.showPaymentButton = true;
    }
    // if(config.result.customerHasPlan.status_customerPlan != 1){
    //   this.showPaymentButton = true;
    // }

  }else{
    this.showPaymentButton = true;
  }
// }
this.planService.plans.emit(config.result.plans)

    }, err =>{
      this.spinner.close();
      this.toast.showError(err);
    });

  }

  selectPalette(event : any){
     this.checked = event.target.value;
  }

  onLogoChange(event : any) {
    const reader = new FileReader();
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];
      reader.readAsDataURL(file);
      reader.onload = () => {
      this.logoName = file.name;
      this.logoType = file.type;
      this.logo64 = reader.result!.toString();
      };
    }
  }

 onPrincipalChange(event : any) {
    const reader = new FileReader();
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];
      // Generar un nombre de archivo único
      const uniqueFileName = 'nombre_principal' + generateUniqueString() + getFileExtension(file.name);
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.principalName = uniqueFileName // Uso del nuevo nombre
      //this.principalName = file.name;
      this.principalType = file.type;
      this.principal64 = reader.result!.toString();
      };
    }
  }

  onPromotionalChange(event : any) {
    const reader = new FileReader();
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];
       // nombre de archivo único
       const uniqueFileName = 'promotional' + generateUniqueString() + getFileExtension(file.name);

      reader.readAsDataURL(file);
      reader.onload = () => {
        this.promotionalName = uniqueFileName; // Uso del nuevo nombre
      //this.promotionalName = file.name;
      this.promotionalType = file.type;
      this.promotional64 = reader.result!.toString();
      };
    }
  }

  sendConfiguration(){

    if(this.checked == undefined){
      return this.toast.showError("Por favor, seleccione una paleta de colores para el template");
    }

    if(this.showPreview){
      this.showPreview = false;
    }

    if(!this.exist){ // EN CASO DE QUE NO EXISTAN ENTONCES COMPROBAMOS QUE NO SE VAYAN VACIAS LAS IMAGENES
      if(this.logoName == null || this.logoName == undefined){
        return this.toast.showError("Por favor, seleccione una imagen para su logo");
      }

      if(this.principalName == null || this.principalName == undefined){
        return this.toast.showError("Por favor, seleccione una imagen para su banner principal");
      }

      if(this.promotionalName == null || this.promotionalName == undefined){
        return this.toast.showError("Por favor, seleccione una imagen para su banner promocional");
      }
    }

    if(!this.form.valid){
      return this.toast.showError("Por favor complete el formulario de Datos Generales");
    }

    this.spinner.open();
   
    let json = { config : this.onboardingConfigID, content : this.form.value, partner : this.securityService.getTokenPartner(), palete : this.selectedPalette , logo : { name : this.logoName, base64 : this.logo64, type : this.logoType}, principal : { name : this.principalName, base64 : this.principal64, type : this.principalType}, promotional : { name : this.promotionalName, base64 : this.promotional64, type : this.promotionalType}};
    this.webService.put(json,this.webService.SECURITY_HOST + "/onboarding/step3").subscribe( response =>{

      this.spinner.close();

      if(!this.exist){
        this.toast.showSuccess("Configuracion guardada correctamente");
      }else{
        this.toast.showSuccess("Configuracion actualizada correctamente");
      }

      if(!this.clickOnPreview){
        if(!this.alreadyHavePlans){
          this.router.navigate(["/plans"], {relativeTo : this.activateRoute, queryParamsHandling : "preserve"}); // ENVIAMOS AL CLIENTE PARA EL STEP4
        }else{
          this.router.navigate(["/edit/portal/"+this.onboardingConfigID+"/my-plans"], {relativeTo : this.activateRoute, queryParamsHandling : "preserve"}); 
        }
      }else{
        this.form.controls["id"].setValue(response.onboardingPortalContent.id);
        this.clickOnPreview = false;
        this.showPreview = true;
        // this.showPreviewPopup();
        this.openModal();
        //window.location.href = this.urlClientPreview;
      }

    }, err =>{
        this.spinner.close();
        this.toast.showError(err)
      }
    );

  }

  showPreviewPopup(){
    let dialogRef: MatDialogRef<any> = this.dialog.open(PreviewPopupComponent, {
      width: '95%',
      disableClose: true,
      data: { url : this.urlClientPreview}
    })
    dialogRef.afterClosed()
      .subscribe(res => {


      });
  }


  preview(){
    this.clickOnPreview = true;
    this.sendConfiguration();
  }

  getUrlTemplatePreview(){
    return this.sanitizer.bypassSecurityTrustResourceUrl(this.urlClientPreview);
  }

  goBack(){
    this.router.navigate(["/portal"], {relativeTo : this.activateRoute ,queryParamsHandling : "preserve"}); // ENVIAMOS AL CLIENTE PARA EL STEP2
  }

  changeTheme(theme: string) {
    if(theme.trim() === "https://dev-onboarding-practical.billcentric.com"){
      this.selectedTheme = 'Práctico';
    }else if (theme.trim() === "https://dev-onboarding-contemporary.billcentric.com"){
      this.selectedTheme = 'Contempo';
    }else{
      this.selectedTheme = 'Básico';
    }

  }

  handlePaletteSelected(palette: any): void {
    this.selectedPalette = palette;
    if(this.selectedPalette == "Billentrix"){
      this.checked = "pal1" ;
      this.pallete1 = true;
    }else if(this.selectedPalette == "Moderna"){
      this.checked = "pal2" ;
      this.pallete2 = true;
    }else if(this.selectedPalette == "Violeta"){
      this.checked = "pal3" ;
      this.pallete3 = true;
    }else{
      this.checked = "pal4" ;
      this.pallete4 = true;
    }
  }

  openModal(): void {
    let component: any; // Variable para almacenar el componente a abrir
    console.log('this.selectedTheme:',this.selectedTheme)
    
    if (this.selectedTheme === 'Básico') {
      component = BasicComponent;
    } else if (this.selectedTheme === 'Práctico') {
      component = PracticalComponent;
    } else if (this.selectedTheme === 'Contempo') {
      component = ContempoComponent;
    }

    if (component) {
      this.dialogRef = this.dialog.open(component, {
        width: '90%',
        height: '100%',
        scrollStrategy: this.overlay.scrollStrategies.reposition()
      });

      this.dialogRef.componentInstance.selectedPalette = this.selectedPalette;

      this.dialogRef.afterClosed().subscribe(result => {
        // Después de que el modal se haya cerrado ejecutar acciones de ser necesario
      });
    }
  }

  closeModal(): void {
    if (this.dialogRef) {
      this.dialogRef.close();
    }
  }

  goToPlans(){
    if(this.step > 3){
      this.router.navigate(["/plans"], {relativeTo : this.activateRoute, queryParamsHandling : "preserve"});
    }else{
      this.toast.showError("No se puede ir a 'Planes' sin antes haber personalizado su template");
    }

  }

 
  goToAccount(){
    this.router.navigate(["/settings"], {relativeTo : this.activateRoute, queryParamsHandling : "preserve"});
  }
 
}
function getFileExtension(fileName: string): string {
  const lastIndex = fileName.lastIndexOf(".");
  if (lastIndex < 0) return ''; 
  return fileName.slice(lastIndex); 
}

function generateUniqueString(): string {
  const randomChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let result = '';
  for (let i = 0; i < 5; i++) {
      result += randomChars.charAt(Math.floor(Math.random() * randomChars.length));
  }
  return new Date().getTime().toString() + result; // Combinar fecha y hora + caracteres aleatorios
}
