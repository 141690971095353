import { Component, OnInit ,Inject} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { WebService } from 'src/app/services/web/web.service';
import {SpinnerService} from '../../../services/spinner/spinner.service';

@Component({
  selector: 'app-fac-popup',
  templateUrl: './fac-popup.component.html',
  styleUrls: ['./fac-popup.component.css']
})
export class FacPopupComponent implements OnInit {

  responseForm : any;

  constructor(
    private webService : WebService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<FacPopupComponent>,
    private spinner : SpinnerService
  ) { }

  ngOnInit(): void {
    this.spinner.open();
    this.responseForm = this.data.form
    setTimeout(() => {
      this.spinner.close();},4000);
  }

  exit() {
    this.webService.get(this.webService.SECURITY_HOST + "/hasplan/" + this.data.id).subscribe(chp =>{
      if(chp.result.status_customerPlan  != 4){
        this.dialogRef.close(chp.status_customerPlan);
      }

    });
  }

}
