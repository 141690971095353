import {Component, OnDestroy, OnInit} from '@angular/core';
import {PortalService} from "./portal.service";
import {WebService} from "../../../services/web/web.service";
import { ToastService } from '../../../services/notification/toast.service';
import {SpinnerService} from '../../../services/spinner/spinner.service';
import { DomSanitizer} from '@angular/platform-browser';
import { Router } from '@angular/router';
import {SecurityService} from "../../../services/security.service";
import {finalize, Subscription} from "rxjs";
import {catchError} from "rxjs/operators";

@Component({
  selector: 'app-my-portals',
  templateUrl: './my-portals.component.html',
  styleUrls: ['./my-portals.component.scss']
})

export class MyPortalsComponent implements OnInit, OnDestroy {
  public myportals : any = [];
  private portalSubscription: Subscription;
  constructor(private securityService : SecurityService, private router: Router,private sanitizer: DomSanitizer,private portalService : PortalService, private webService : WebService,private spinner : SpinnerService,private toast : ToastService) { }
  //control color
  get isEmpty(): boolean {
    return this.myportals.length === 0;
  }

  ngOnInit(): void {
    this.initializePortalSubscription();
    this.loadPortals();
  }

  ngOnDestroy(): void {
    if (this.portalSubscription) {
      this.portalSubscription.unsubscribe();
    }
  }

  private initializePortalSubscription(): void {
    this.portalSubscription = this.portalService.portals.subscribe(portals => {
      this.myportals = [...portals];
    });
  }

  private loadPortals(): void {
    this.spinner.open();

    const partner = this.securityService.getTokenPartner();
    if (!partner) {
      this.spinner.close();
      this.toast.showInfo('No posees suscripciones creadas, por favor crea tu primera suscripcion');
      return;
    }

    this.webService.get(`${this.webService.SECURITY_HOST}/onboarging/partner/${partner}`)
        .pipe(
            catchError(error => {
              console.error('ERROR EN SEGURIDAD: ', error);
              throw error;
            }),
            finalize(() => this.spinner.close())
        )
        .subscribe(response => {
          const processedPortals = this.processPortals(response.result);
          this.portalService.portals.emit(processedPortals);
        });
  }

  private processPortals(portals: any[]): any[] {
    return portals
        .map(portal => ({
          ...portal,
          url_template: `${portal.url_template}/preview/${this.getServiceId(portal)}`
        }))
        .sort((a, b) => b.id - a.id);
  }

  private getServiceId(portal: any): number {
    return typeof portal.service === 'object' ? portal.service.id : portal.service;
  }

  public goSettings(): void {
    this.router.navigate(['/settings'], {
      queryParams: { new: true }
    });
  }



}
