import { Component, OnInit } from '@angular/core';
import { Validators, FormGroup, FormControl } from '@angular/forms';
import { WebService}  from '../services/web/web.service';
import { ToastService } from '../services/notification/toast.service';
import {SpinnerService} from '../services/spinner/spinner.service';
import {ActivatedRoute, Router} from '@angular/router';
import {SecurityService} from "../services/security.service";

@Component({
  selector: 'app-account-settings',
  templateUrl: './account-settings.component.html',
  styleUrls: ['./account-settings.component.scss']
})
export class AccountSettingsComponent implements OnInit {
  showMenu: boolean = false;
  showHelpText: boolean = false;
  partnerForm: FormGroup ;

  banks : any;
  countries : any;
  cities : any;
  currencies : any;
  numbers : any;
  exist : Boolean = false;
  isNewService : Boolean = false;
  isPartnerCreated : Boolean = false;
  onboardingConfigID: any;
  isMailContactSended : Boolean = false;
  name_portal: any;

  constructor(private securityService : SecurityService, private activateRoute : ActivatedRoute,private router : Router,private spinner : SpinnerService,private webService : WebService,private toast : ToastService) {
    this.partnerForm = new FormGroup({
      id: new FormControl(''),
      name_partner: new FormControl('', Validators.required),
      phone_partner: new FormControl('', Validators.required),
      adress_partner : new FormControl('', Validators.required),
      country : new FormControl(''),
      city: new FormControl('', Validators.required),
      currency_id: new FormControl('', Validators.required),
      status_partner: new FormControl(1, Validators.required),
      bank: new FormControl(''),
      whatsappNumber: new FormControl(''),
      email_partner :  new FormControl('info@billcentrix.com', [Validators.required,Validators.email]),
      feeRate_partner : new FormControl(1.00),
      name_portal : new FormControl('', Validators.required),
      sgSenderId : new FormControl('4591613', Validators.required),
      acummulate_debt : new FormControl(false, Validators.required),
      create_email_notification : new FormControl(false, Validators.required)
    });
   }

   ngOnInit(): void {
     console.log(this.securityService.getDecodedToken())
    this.spinner.open();
    let partner = -1;
    if(this.securityService.getTokenPartner() != null || this.securityService.getTokenPartner() != undefined){
      partner = this.securityService.getTokenPartner()
    }

    this.webService.get(this.webService.SECURITY_HOST + "/onboardingBanks/"+this.webService.ONBOARDING_ID + "/partner/" + partner).subscribe( banks =>{
      
      this.banks = banks.result;

      // BUSCAMOS LOS PAISES REGISTRADOS
      this.webService.get(this.webService.SECURITY_HOST + "/country").subscribe( response =>{

          this.countries = response.result;
         
          this.webService.get(this.webService.SECURITY_HOST + "/whatsapp").subscribe( response =>{

            this.numbers = response.result;

            if(this.securityService.getTokenPartner() != null){ // ESTO LO HACEMOS EN CASO DE QUE EL PARTNER YA EXISTA, ENTONCES PUEDA EDITAR INFORMACION
              this.isPartnerCreated = true;
            
              // BUSCAMOS LA INFORMACION DEL PARTNER
              this.webService.get(this.webService.SECURITY_HOST + "/partner/" + this.securityService.getTokenPartner()).subscribe(response =>{
               
                this.activateRoute.queryParams.subscribe( queryParams =>{
                 
                   // LLENAMOS EL FORMULARIO CON LA INFORMACION CORRESPONDIENTE
                  this.partnerForm.controls["id"].setValue(response.result.id);
                  this.partnerForm.controls["name_partner"].setValue(response.result.name_partner);
                  this.partnerForm.controls["phone_partner"].setValue(response.result.phone_partner);
                  this.partnerForm.controls["adress_partner"].setValue(response.result.adress_partner);
                  if(response.result.city != null){
                    this.partnerForm.controls["country"].setValue(response.result.city.country.id);
                    this.cityByCountry(response.result.city.country.id);
                    this.partnerForm.controls["city"].setValue(response.result.city.id);
                  }

                  this.partnerForm.controls["create_email_notification"].setValue(response.result.create_email_notification);


                 
                  if(queryParams['new'] && !queryParams['config']){

                    this.isNewService = true;
                                       
                  }else{
                    // this.partnerForm.controls['currency_id'].disable();
                    this.exist = true;
                    // this.onboardingConfigID = sesion.config[0];
                    this.onboardingConfigID = queryParams['config']
                    this.getConfigByID();
                    
                    this.partnerForm.controls["status_partner"].setValue(response.result.status_partner);
                    if(response.result.bank !== null){
                      this.partnerForm.controls["bank"].setValue(response.result.bank.id);
                    }
                    if(response.result.whatsappNumber !== null){
                      this.partnerForm.controls["whatsappNumber"].setValue(response.result.whatsappNumber.id);
                    }
                    this.partnerForm.controls["email_partner"].setValue(response.result.email_partner);
                    this.partnerForm.controls["feeRate_partner"].setValue(response.result.feeRate_partner);
                  }

                  this.spinner.close();

                }, err =>{
                  this.spinner.close();
                  this.toast.showError(err);
                });

              }, err =>{
                this.spinner.close()
                console.log(err);
                this.toast.showError(err);
              })
            }else{
              console.log("ESTA HACUENDO ESTE")
              this.spinner.close();
            }

          }, err =>{
            this.spinner.close();
            console.log(err);
            this.toast.showError(err);
          })



      }, err =>{
        this.spinner.close();
        console.log(err);
        this.toast.showError(err);
      })


    }, err =>{
      this.spinner.close();
      console.log(err);
      this.toast.showError(err);
    })

  }

  getConfigByID(){
    // BUSCAMOS TODOS LOS BANCOS
    let partner = -1;
    if(this.securityService.getTokenPartner() != null || this.securityService.getTokenPartner() != undefined){
      partner = this.securityService.getTokenPartner()
    }

    this.webService.get(this.webService.SECURITY_HOST + "/onboardingConfig/" + this.onboardingConfigID + "?partner=" + partner).subscribe( config =>{
      this.spinner.close();
      this.name_portal = config.result.service.name_service;
      this.partnerForm.controls["name_portal"].setValue( this.name_portal);
      this.partnerForm.controls["currency_id"].setValue( config.result.service.currency.id);
     
    }, err =>{
      this.spinner.close();
      this.toast.showError(err);
    });
  }

  cityByCountry(country : any){

    this.spinner.open();

    // BUSCAMOS LA CIUDADES DEPENDIENDO DEL PAIS SELECCIONADO
    this.webService.get(this.webService.SECURITY_HOST + "/city/country/" + country).subscribe( response =>{
      this.cities = response.result;
      if (this.cities.length > 0) {
      this.currencies = this.cities[0].country.currencies;
      }
      this.spinner.close();

    }, err =>{
      this.spinner.close();
      console.log(err);
      this.toast.showError(err);

    })

  }


  send(){

    if(!this.partnerForm.valid){
      this.spinner.close();
      this.toast.showError("Datos incompletos o invalidos, por favor verifique...");
      return;
    }


    const data =  this.partnerForm.value;


    if(data.bank !== ""){
      data.bank = { id : +data.bank };
    }else{
      delete data.bank;
    }

    if(data.create_email_notification == null){
      data.acummulate_debt == false;
    }

    data.city = { id : +data.city };
    data.country = { id : +data.country };

    if(data.whatsappNumber !== ""){
      data.whatsappNumber = { id : Number(data.whatsappNumber) };
    }else{
      delete data.whatsappNumber ;
    }

    if(!this.isNewService){ // EN CASO QUE NO SEA UN SERVICIO NUEVO - es que estamos creando el partner - servicio - config
      this.sendNewPartner(data);
    }else{ // ESTE CASO ES CUANDO SI ES UN SERVICIO NUEVO - Creamos servicio y config
      this.sendNewServiceConfig(data)
    }

  }

  sendNewServiceConfig(data : any){
    this.spinner.open();
    this.webService.post(data,this.webService.SECURITY_HOST + "/onboarding/service").subscribe(response =>{

      this.toast.showSuccess("Servicio creado correctamente");
      this.spinner.close();
      this.router.navigate(["/plans"], {relativeTo : this.activateRoute, queryParams : { config : response.result.id } ,queryParamsHandling : "merge"}); // ENVIAMOS AL CLIENTE PARA EL STEP2

    }, err =>{
      this.spinner.close();
      console.log(err);
      this.toast.showError(err);
    });
  }

  // TODO - ADECUAR ESTO A LA NUEVA LOGICA - SE DEBE EJECUTAR UN LOGIN PARA TENER EL ID DEL PARTNER NUEVO QUE SE ESTA CREANDO EN EL TOKEN
  sendNewPartner( data : any){
    this.spinner.open();
    this.webService.post({partner:data, user : this.securityService.getTokenUser()},this.webService.SECURITY_HOST + "/onboarding/partner").subscribe(response =>{
      this.securityService.refreshToken().subscribe( securityResponse =>{
        this.spinner.close();
        this.toast.showSuccess("Compañía creada correctamente");
        this.spinner.close();
        this.isPartnerCreated = true;
        this.router.navigateByUrl("/plans");
      }, error => {
        this.spinner.close();
        console.log(error);
        this.toast.showError(error);
      })

      // // HEMOS CREAMOS EL PARTNER POR LO CUAL ACTUALIZAMOS EL OBJETO EN SESION PARA MANTENER EL ID DEL PARTNER
      // let array = [];
      // // @ts-ignore
      // array.push(response.result.onboardingConfig)
      //
      //
      // // if(!this.isMailContactSended){
      // //   this.router.navigateByUrl("/step2"); // ENVIAMOS AL CLIENTE PARA EL STEP2
      // // }

    }, err =>{
      this.spinner.close();
      console.log(err);
      this.toast.showError(err);
    });
  }

  update(){
    this.spinner.open();

    if(!this.partnerForm.valid || this.partnerForm.value.bank == ""){
      this.spinner.close();
      this.toast.showError("Datos incompletos o invalidos, por favor verifique...")
      return;
    }

    const data =  this.partnerForm.value;

    data.bank = { id : +data.bank };
    data.city = { id : +data.city };

    if(data.whatsappNumber !== ""){
      data.whatsappNumber = { id : Number(data.whatsappNumber) };
    }else{
      delete data.whatsappNumber ;
    }

    this.webService.put({partner:data},this.webService.SECURITY_HOST + "/partner/" + data.id).subscribe(response =>{

      // HEMOS CREAMOS EL PARTNER POR LO CUAL ACTUALIZAMOS EL OBJETO EN SESION PARA MANTENER EL ID DEL PARTNER
      this.toast.showSuccess("Cambios guardados correctamente")
      this.spinner.close();
      this.router.navigateByUrl("/plans"); // ENVIAMOS AL CLIENTE PARA EL STEP2

    }, err =>{
      this.spinner.close();
      console.log(err);
      this.toast.showError(err);
    });

  }

  open(){
    console.log("ABRIR");
  }

  goBack(){
    this.router.navigate(["/my-portals"]); 
  }

  onlyNumberKey(event : any){
    return (event.charCode == 8 || event.charCode == 0) ? null : event.charCode >= 48 && event.charCode <= 57;
  }

  sendContactMail(type : number){
    

      var json : any = {};
      json.type = type


    this.isMailContactSended = true;

      if(this.isPartnerCreated){
        this.spinner.open();
          // mandamos el correo
        json.partner = this.partnerForm.value.id;
        this.webService.post(json, this.webService.SECURITY_HOST + "/onboarding/step1/sendMail").subscribe( response =>{
          console.log(response);
          this.spinner.close();
          this.toast.showSuccess("En breve uno de nuestros ejecutivos se estará comunicando con usted para procesar su requerimiento...")
        }, err =>{
          this.spinner.close();
          this.toast.showError("No se pudo enviar el correo, por favor contacta con el administrador");
        });

      }else{

        console.log("Entro por el ELSE en sendContactMail - Verificar funcionamiento");
        // if(!this.partnerForm.valid){
        //   return this.toast.showInfo("Por favor completa los datos del formulario para crear los datos correspondientes");
        // }

        // this.send();

      }

  }

  omit_special_char(event: { charCode: any; }){   
    var k;  
    k = event.charCode;  //         k = event.keyCode;  (Both can be used)
    return((k > 64 && k < 91) || (k > 96 && k < 123) || k == 8 || k == 32 || (k >= 48 && k <= 57)); 
   }

  toggleMenu() {
    this.showMenu = !this.showMenu;
  }

  goToPlans(){
    if(this.onboardingConfigID){
      this.router.navigate(["/plans"], {relativeTo : this.activateRoute, queryParamsHandling : "merge"});
    }else{
      this.toast.showError("No se puede ir a 'Planes' sin antes haber creado su cuenta");
    }
    
    
  }

  goToTemplates(){
    
    if(this.onboardingConfigID){
      this.router.navigate(["/portal"], {relativeTo : this.activateRoute, queryParams : { config : this.onboardingConfigID } ,queryParamsHandling : "merge"});
    }else{
      this.toast.showError("No se puede ir a 'Planes' sin antes haber creado su cuenta");
    }

  }

  goToFeatures(){
    if(this.onboardingConfigID){
      this.router.navigate(["/personalize"], {relativeTo : this.activateRoute, queryParamsHandling : "preserve"});
    }else{
      this.toast.showError("No se puede ir a 'Personalizar' sin antes haber creado su cuenta");
    }
   
  }

}
