import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { Router } from '@angular/router';
import {SecurityService} from "../services/security.service";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

    constructor(private authService: SecurityService, private router: Router) { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // Excluir el servicio de autenticación (login)
        if (req.url.includes("/resend-pin") || req.url.includes("/billcentrix/V2/resetPassword") || req.url.includes("/billcentrix/V2/user") || req.url.includes("/login") || req.url.includes("/token") || req.url.includes("/log") || req.url.includes("/validate")) {
            return next.handle(req);  // No se modifica la solicitud si es la URL de login
        }

        let token = this.authService.getToken();

        if (token && !this.authService.isTokenExpired()) {

            if(req.url.includes("/auth/refresh")){
                token = this.authService.getRefreshToken();
            }
            // Clonar la solicitud y agregar el token en el encabezado Authorization
            const cloned = req.clone({
                headers: req.headers.set('Authorization', 'Bearer ' + token)
            });
            return next.handle(cloned);
        } else {
            // Si el token está expirado o no existe, redirigir al login
            this.authService.logout();
            this.router.navigate(['/login']);
            return throwError(() => new Error('Token expirado o no válido, por favor inicie sesion de nuevo'));
        }
    }
}
