import { Component, OnInit, ViewChild,ChangeDetectorRef } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Validators, FormGroup, FormControl } from '@angular/forms';
import { WebService}  from '../services/web/web.service';
import { ToastService } from '../services/notification/toast.service';
import {SpinnerService} from '../services/spinner/spinner.service';
import {ActivatedRoute, Router} from '@angular/router';
import { Location,DatePipe } from '@angular/common';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { PortalService } from '../account-settings/portal/my-portals/portal.service';
import { HttpClient } from '@angular/common/http';
import { TableService } from '../services/pager/pager';

@Component({
  selector: 'app-customer-update',
  templateUrl: './customer-update.component.html',
  styleUrls: ['./customer-update.component.scss'],
  providers:[DatePipe]
})
export class CustomerUpdateComponent implements OnInit {

  form: FormGroup ;
  exist : Boolean = false;
  isNewService : Boolean = true;

  taxes : any[] = [{value:true,description:"SI"},{value:false,description:'NO'}];
  customer: any;
  addresses : any[] = [];
  suscriptions : any[] = [];


   // PARA MANEJO DE TABLAS
   @ViewChild(MatPaginator) paginator: MatPaginator | undefined;
   @ViewChild(MatSort) sort: MatSort | undefined;
   displayedColumns: string[] = ['suscription', 'adress','corregimiento','province','actions'];
   dataSource: any;


  constructor( public  tableService: TableService<any>, private cdr: ChangeDetectorRef,private datePipe: DatePipe,private http : HttpClient,private dialog: MatDialog,private location: Location ,private portalService : PortalService,private activateRoute : ActivatedRoute,private router : Router,private spinner : SpinnerService,private webService : WebService,private toast : ToastService) {
    this.form = new FormGroup({
      id: new FormControl('', [
        Validators.required
      ]),
      name_customer: new FormControl('', [
        Validators.required
      ]),
      lastName_customer: new FormControl('', [
        Validators.required
      ]),
      code_customer: new FormControl('', [
        Validators.required
      ]),
      identifier_customer: new FormControl('', [
        Validators.required
      ]),
      email_customer: new FormControl('', [
        Validators.email
      ]),
      phoneNumber_customer: new FormControl(''),
      adress_customer: new FormControl(''),
      birthDate_customer: new FormControl(''),
      status_customer: new FormControl('', [
        Validators.required
      ]),
      sucursal_id: new FormControl(''),
      taxexempt: new FormControl(''),
    });
  }

  ngOnInit(): void {
    // 
    // this.spinner.open();
    this.activateRoute.params.subscribe(params =>{

      // 
      
        //Se carga la informacion del cliente seleccionado para la actualizacion
        this.webService.get(this.webService.SECURITY_HOST + "/partner/" +  JSON.parse(localStorage.getItem('sesion') || '{}').partner + "/customer/" + params["id"]).subscribe(customer => {
          this.customer = customer.result;
          //Se llenan los componentes del formulario con la informacion
          this.form.controls["id"].setValue(this.customer.id);
          this.form.controls["name_customer"].setValue(this.customer.name_customer);
          this.form.controls["lastName_customer"].setValue(this.customer.lastName_customer);
          if(this.customer.code_customer != null){
            this.form.controls["code_customer"].setValue(this.customer.code_customer);
          }
          this.form.controls["adress_customer"].setValue(this.customer.adress_customer);
          this.form.controls["phoneNumber_customer"].setValue(this.customer.phoneNumber_customer);
          this.form.controls["birthDate_customer"].setValue(this.customer.birthDate_customer);
          this.form.controls["email_customer"].setValue(this.customer.email_customer);
          this.form.controls["identifier_customer"].setValue(this.customer.identifier_customer);
          this.form.controls["sucursal_id"].setValue(this.customer.sucursal_id);
          this.form.controls["status_customer"].setValue(this.customer.status_customer);
          this.form.controls["taxexempt"].setValue(this.customer.taxexempt);

          this.webService.get(this.webService.SECURITY_HOST + "/hasplan/partner/" + JSON.parse(localStorage.getItem('sesion') || '{}').partner + "/customer/" + params["id"]).subscribe(suscriptions =>{
            this.suscriptions = [];
              for(let s of suscriptions.result){
                if(s.adress_id != null){
                  this.suscriptions.push(s);
                  this.spinner.open();
                  this.webService.get(this.webService.SECURITY_HOST + "/adress/" +  s.adress_id).subscribe(address =>{
                    let index = this.suscriptions.findIndex(t => t.id == s.id )
                    this.suscriptions[index].address = address.result;
                    this.dataSource = new MatTableDataSource<any>(this.suscriptions);
                    this.tableService.selection.clear();
                    this.spinner.close();
                  },err =>{
                    console.log(err);
                    this.spinner.close();
                  });
                }else{
                  this.dataSource = new MatTableDataSource<any>(this.suscriptions);
                    this.tableService.selection.clear();
                    this.spinner.close();
                }
              }
              
          }, err =>{
            console.log(err);
          })
         

        }, err =>{
          this.spinner.close();
          this.toast.showError(err);
          setTimeout(() => {
            this.location.back();
          }, 2000); // 3000 milliseconds = 3 seconds
        });
    });


  }

  updateAddress(row : any){
    this.router.navigate(['./address/' + row.address.id], {relativeTo: this.activateRoute, queryParamsHandling: 'preserve'});
  }

  update(){
   
    if(!this.form.valid){
      return this.toast.showInfo("Por favor complete el formulario de Datos generales");
    }
    this.spinner.open();
    this.customer = this.form.value;
    this.customer.partner = {id: +JSON.parse(localStorage.getItem('sesion') || '{}').partner};
    this.customer.birthDate_customer = this.datePipe.transform(this.customer.birthDate_customer, 'yyyy-MM-dd');
  
   
    // Se actualiza la informacion del cliente
    this.webService.put(this.customer,this.webService.SECURITY_HOST + "/partner/" +  JSON.parse(localStorage.getItem('sesion') || '{}').partner + "/customer/" +this.customer.id).subscribe(customer => {
        this.spinner.close()
        this.toast.showSuccess("Cliente actualizado correctamente");
        this.location.back();
    }, (err) => {
      this.spinner.close();
      console.log(err);
      this.toast.showError(err?.error?.message);
    });

  }

  goBack(){
    this.router.navigate(["/reports"]); 
  }

  omit_special_char(event: { charCode: any; }){   
    var k;  
    k = event.charCode;  //         k = event.keyCode;  (Both can be used)
    return((k > 64 && k < 91) || (k > 96 && k < 123) || k == 8 || k == 32 || (k >= 48 && k <= 57)); 
   }
   onlyNumberKey(event : any){
     return (event.charCode == 8 || event.charCode == 0) ? null : event.charCode >= 48 && event.charCode <= 57;
   }


}
