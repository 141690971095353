import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {Router} from "@angular/router";
import {SpinnerService} from "../services/spinner/spinner.service";
import {WebService} from "../services/web/web.service";
import {ToastService} from "../services/notification/toast.service";
import {AuthService} from "../services/auth/auth.service";
import {Location} from "@angular/common";
import {SecurityService} from "../services/security.service";

@Component({
  selector: 'app-validate-pin',
  templateUrl: './validate-pin.component.html',
  styleUrls: ['./validate-pin.component.scss']
})
export class ValidatePinComponent implements OnInit {
  validateForm: FormGroup ;

  constructor(private securityService : SecurityService,private router : Router,private spinner : SpinnerService,private webService : WebService,private toast : ToastService, private auth : AuthService,
              private location : Location) {
    this.validateForm = new FormGroup({
      pin: new FormControl('', [Validators.required]),
      email : new FormControl(''),
      partner : new FormControl('')
    });
  }

  ngOnInit(): void {

  }

  validate(){

    // this.spinner.open();

    this.validateForm.setValue({
      pin: this.validateForm.get('pin')?.value,
      email: this.securityService.getTokenSub(),  // Replace with your desired email value
      partner: null      // Replace with your desired partner value
    });

    console.log(this.validateForm.value)
    // // SOLICITAMOS TOKEN
    this.spinner.open();
    this.webService.post(this.validateForm.value,this.webService.SECURITY_HOST + "/auth/validate-pin").subscribe(res =>{

      this.spinner.close();
      this.securityService.isPinValidated(true);
      this.toast.showSuccess("Ha iniciado sesión correctamente");
      this.router.navigate(['/my-portals']);

    }, err =>{
      this.spinner.close();
      console.log(err);
      this.toast.showError("Pin invalido, por favor intenta de nuevo");
      this.securityService.isPinValidated(false);
      // this.router.navigate(['/login']);
    })


  }

  goLogin(){
    this.router.navigate(['/login']);
  }

  resendPin(){
    let json = { username : this.securityService.getTokenSub(), password : "esteNoesElPassword", partner : null}
    console.log(json)
    this.spinner.open();
    this.webService.post(json, this.webService.SECURITY_HOST + "/auth/resend-pin").subscribe(res =>{
      this.spinner.close();
      this.toast.showSuccess("PIN reenviado exitosamente")
    }, error => {
      this.spinner.close();
      this.toast.showError(error);
    });
  }

}
