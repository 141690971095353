<body>
<div class="main">
    <div class="grid-overlay">
        <div class="nav">
        </div>
        <div class="logoblock"><img (click)="goLogin()" src="../../assets/images/BRAND.png" alt="Logo Billcentrix">
        </div>


        <div class="background-white">
            <h1>Valida tu identidad</h1>
            <p>Hemos enviado un codigo PIN a tu correo electrónico. Por favor, Ingresalo para continuar</p>
            <form class="user" [formGroup]="validateForm" (ngSubmit)="validate()" >
                <input [formControl]="validateForm.controls['pin']"  type="number" class="form-control form-control-user" id="exampleInputEmail" aria-describedby="emailHelp">
                <small
                        *ngIf="validateForm.controls['pin'].hasError('required') && validateForm.controls['pin'].touched"
                        class="form-error-msg"> Campo es requerido </small>

                <div class="options">
                    <button class="crear" type="btn" [disabled]="validateForm.invalid" >Validar PIN</button>
                </div>
                <div class="transparent-background">
                    <p>¿No recibiste el PIN?</p>
                    <a (click)="resendPin()">Reenviar PIN</a>
                </div>
            </form>
        </div>
    </div>

</div>
<app-footer></app-footer>





